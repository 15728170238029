import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../../Utilities/utility";
import "./Configuration.scss";
import axios from "axios";
import * as myFunctions from "../Formula/Formula";

export function InfoIconDialog(props) {
  const [data, setData] = useState([]);
  const startMethod = props.startMethod;
  const rowData = props.selectedRowData;
  const configReqid = rowData.id;
  const api = axios.create({
    baseURL: "SystemInfeed",
  });
  useEffect(() => {
    //getConfigData();
    console.log("rowData", rowData);
    console.log("startMethod", startMethod);
    console.log("rowDataid", rowData.id);
  }, []);
  const getConfigData = () => {
    let paramdetails = {
      ConfiqReqID: parseInt(configReqid),
    };
    // const res = await api.post("/GetAdminInfoData", paramdetails);
    //return res.data;
    api
      .post("/GetAdminInfoData", paramdetails)
      .then((res) => {
        //setMotorRating(res.data);
        //dispatch(motorRatingInputOptions(res.data));
        setData(res.data);
        console.log("ConfigData", res.data);
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.", error);
      });
  };
  const columns = [
    {
      title:
        startMethod === 1 ? "DOL" : startMethod === 2 ? "RDOL" : "Star Delta",
      field: "dol",
    },
    { title: "Unit", field: "unit" },
    { title: "Data", field: "data" },
  ];

  const motorStartingTimeConstant = 6;
    const motorStartingTime = myFunctions.getMotorStartingTime(
        rowData.motorStartingTime
  );

  const SDmotorStartingTime = myFunctions.getMotorStartingTime(
      rowData.motorStartingTime
  );

  const dolColumns = [
    {
      dol: "",
      unit: "SwitchGear Data",
      data: "",
    },
    {
      dol: "FLA - Line",
      unit: "A",
      // 'data': '5.2'
      data:
        rowData.flaline != ""
          ? parseFloat(rowData.flaline).toFixed(4)
          : rowData.flaline,
    },
    {
      dol: "Motor Starting time",
      unit: "Sec.",
      // data:
      //   myFunctions
      //     .getMotorStartingTime(rowData.motorStartingTime)
      //     .toFixed(4) || "",
      data: parseFloat(motorStartingTime).toFixed(2),
    },
    {
      dol: "LRA (For contactor selection)",
      unit: "A",
      data:
        rowData.lraForContactor != ""
          ? parseFloat(rowData.lraForContactor).toFixed(4)
          : rowData.lraForContactor,
    },
    {
      dol: "LRA (For OL char. selection)",
      unit: "A",
      data:
        rowData.lraForOlselection != ""
          ? parseFloat(rowData.lraForOlselection).toFixed(4)
          : rowData.lraForOlselection,
    },
    // SCPD Selection
    {
      dol: "",
      unit: "SCPD Selection",
      data: "",
    },
    {
      dol: "FLA Line tolerance upper",
      unit: "A",
      data: rowData.flalinetolerance,
    },
    {
      dol: "SCPD rating Current",
      unit: "",
      data: parseFloat(rowData.scpdratingCurrent).toFixed(4),
    },
    // {
    //     'dol': 'SCPD Current Range',
    //     'unit': '',
    //     'data': '6.3'
    // },
    {
      dol: "SCPD size",
      unit: "",
      data: rowData.scpdsize,
    },
    {
      dol: "SCPD MLFB",
      unit: "",
      // 'data': '3RV2011-1GA10',
      data: rowData.scpdmlfb,
    },
    {
      dol: "SCPD kA",
      unit: "",
      data: rowData.scpdka,
    },
    {
      dol: "SC Factor",
      unit: "",
      data: rowData.scfactor,
    },
    {
      dol: "SC Based On LRA",
      unit: "A",
      data:
        rowData.lraSc != "" && !isNaN(rowData.lraSc)
          ? parseFloat(rowData.lraSc).toFixed(4)
          : rowData.lraSc,
    },
    {
      dol: "SC LRA Tolerence Band 20%",
      unit: "A",
      // 'data': '65.5 - 98.3',
      data: rowData.lraSctolerance,
    },
    {
      dol: "Let through of SCPD",
      unit: "kA2s",
      data: rowData.scpdletThrough,
    },
    {
      dol: "SCPD higher current ≥ Required higher current",
      unit: "",
      data:
        rowData.isScpdhighercurrent == true ||
        rowData.isScpdhighercurrent == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: "OL tripping zone time on LRA ≥ motor starting time",
      unit: "",
      data:
        rowData.isOltztlrahigher == true || rowData.isOltztlrahigher == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: "SC lower band > 10% of LRA",
      unit: "",
      data:
        rowData.isSclowerBand == true || rowData.isSclowerBand == "Yes"
          ? "Yes"
          : "No",
    },
    // Contactor Selection
    {
      dol: "",
      unit: "Contactor Selection",
      data: "",
    },
    {
      dol: "MPCB Rating for Contactor Selection",
      unit: "A",
      data: parseFloat(rowData.contactorSiriusRatingMaster).toFixed(4),
    },
    {
      dol: "MLFB",
      unit: "",
      data: rowData.contactorMlfb,
    },
    {
      dol: "Contactor Rating",
      unit: "",
      data: rowData.contactorRating,
    },
    {
      dol: "Selected Contactor frame Size",
      unit: "",
      data: rowData.contactorFrameSize,
    },
    {
      dol: "Thermal rating Current for (10 sec.)",
      unit: "A",
      data:
        rowData.thermalRatingCurrent != ""
          ? rowData.thermalRatingCurrent
          : "NA",
    },
    {
      dol: "Let through",
      unit: "kA2s",
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : isNaN(rowData.letThrough)
          ? "NA"
          : rowData.letThrough,
    },
    {
      dol: "Value with safety factor 3",
      unit: "kA2s",
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : isNaN(rowData.safetyFactorValue)
          ? "NA"
          : rowData.safetyFactorValue != ""
          ? parseFloat(rowData.safetyFactorValue).toFixed(4)
          : rowData.safetyFactorValue,
    },
    {
      dol: "Contactor rated current ≥ FLA",
      unit: "",
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : rowData.isContactorRatedcurrent == true ||
            rowData.isContactorRatedcurrent == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: "Contactor 10 sec. rating > LRA",
      unit: "",
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : rowData.isContactor10sRating == true ||
            rowData.isContactor10sRating == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: "Contactor let through > SCPD let through",
      unit: "",
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : rowData.adminEnquiryType == 2 && rowData.typesOfCoordination == 1
          ? "NA"
          : rowData.isContactorLetThrough == true ||
            rowData.isContactorLetThrough == "Yes"
          ? "Yes"
          : "No",
    },
    // Overload Relay selection
    {
      dol: "",
      unit: "Overload Relay selection",
      data: "",
    },
    {
      dol: "Condition for OLR",
      unit: "A",
      // 'data': '2.7 - 3.0',
      data: rowData.olrcondition,
    },
    {
      dol: "OLR rating",
      unit: "A",
      data: parseFloat(rowData.olrrating).toFixed(4),
    },
    {
      dol: "OLR range",
      unit: "",
      // 'data': '2.2 - 3.2',
      data: rowData.olrRange,
    },
    {
      dol: "MLFB",
      unit: "",
      // 'data': '3RU2116-1DB0',
      data: rowData.olrmlfb,
    },
    {
      dol: "OL tripping zone time on LRA",
      unit: "Sec.",
      data: rowData.olTrippingZoneLra,
    },
    {
      dol: "LRA OL Tripping Tolerence Band 20%",
      unit: "Sec.",
      // 'data': '10.0 - 15.0',
      data: rowData.olrtrippingTolerance,
    },
    {
      dol: "OLR higher current ≥ Required higher current",
      unit: "",
      data:
        rowData.isOlrhighercurrent == true ||
        rowData.isOlrhighercurrent == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: "OL tripping zone time on LRA ≥ motor starting time",
      unit: "",
      data:
        rowData.isOlrtztlrahigher == true || rowData.isOlrtztlrahigher == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: 'IF above both conditions are "Yes" then selection is correct',
      unit: "",
      data: rowData.olrbothCondition,
    },
  ];

  const starDeltaColumns = [
    {
      dol: "",
      unit: "SwitchGear Data",
      data: "",
    },
    {
      dol: "FLA - Line",
      unit: "A",
      // 'data': '5.2'
      data:
        rowData.flaline != ""
          ? parseFloat(rowData.flaline).toFixed(4)
          : rowData.flaline,
    },
    {
      dol: "FLA - Phase",
      unit: "A",
      data: parseFloat(rowData.flaphase).toFixed(4),
    },
    {
      dol: "Motor Starting time",
      unit: "Sec.",
      // data:
      //   myFunctions
      //     .getMotorStartingTime(rowData.motorStartingTime)
      //     .toFixed(4) || "",
      data: parseFloat(SDmotorStartingTime).toFixed(2),
    },
    {
      dol: "LRA",
      unit: "A",
      data: parseFloat(rowData.lra).toFixed(4),
    },
    {
      dol: "LRA (For contactor selection)",
      unit: "A",
      data: parseFloat(rowData.lraForContactor).toFixed(4),
    },
    {
      dol: "LRA (For OL char. selection)",
      unit: "A",
      data:
        rowData.lraForOlselection != ""
          ? parseFloat(rowData.lraForOlselection).toFixed(4)
          : rowData.lraForOlselection,
    },
    {
      dol: "Star Current",
      unit: "A",
      data:
        rowData.starCurrent != ""
          ? parseFloat(rowData.starCurrent).toFixed(4)
          : rowData.starCurrent,
    },
    // SCPD Selection
    {
      dol: "",
      unit: "SCPD Selection",
      data: "",
    },
    {
      dol: "FLA Line tolerance upper",
      unit: "A",
      data: rowData.flalinetolerance,
    },
    {
      dol: "SCPD rating Current",
      unit: "",
      data: parseFloat(rowData.scpdratingCurrent).toFixed(4),
    },
    {
      dol: "SCPD MLFB",
      unit: "",
      data: rowData.scpdmlfb,
    },
    {
      dol: "SCPD size",
      unit: "",
      data: rowData.scpdsize,
    },
    {
      dol: "SCPD kA",
      unit: "",
      data: rowData.scpdka,
    },
    {
      dol: "SC Factor",
      unit: "",
      data: rowData.scfactor,
    },
    {
      dol: "SC Based On LRA",
      unit: "A",
      data:
        rowData.lraSc != "" && !isNaN(rowData.lraSc)
          ? parseFloat(rowData.lraSc).toFixed(4)
          : rowData.lraSc,
    },
    {
      dol: "SC LRA Tolerence Band 20%",
      unit: "A",
      data: rowData.lraSctolerance,
    },
    {
      dol: "Let through of SCPD",
      unit: "kA2s",
      data: rowData.scpdletThrough,
    },
    {
      dol: "SCPD higher current ≥ Required higher current",
      unit: "",
      data:
        rowData.isScpdhighercurrent == true ||
        rowData.isScpdhighercurrent == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: "SC lower band > 10% of LRA",
      unit: "",
      data:
        rowData.isSclowerBand == true || rowData.isSclowerBand == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: 'IF above conditions are "Yes" then selection is correct',
      unit: "",
      data: rowData.scpdbothCondition,
    },
    // Contactor Line/Delta Selection
    {
      dol: "",
      unit: "Contactor Line/Delta Selection",
      data: "",
    },
    {
      dol: "MPCB Rating for Contactor Selection",
      unit: "A",
      data: parseFloat(rowData.contactorSiriusRatingMaster).toFixed(4),
    },
    {
      dol: "MLFB",
      unit: "",
      data: rowData.contactorMlfb,
    },
    {
      dol: "Contactor Rating",
      unit: "",
      data: rowData.contactorRating,
    },
    {
      dol: "Selected Contactor frame Size",
      unit: "",
      data: rowData.contactorFrameSize,
    },
    {
      dol: "Thermal rating Current for (10 sec.)",
      unit: "A",
      data:
        rowData.thermalRatingCurrent != ""
          ? rowData.thermalRatingCurrent
          : "NA",
    },
    {
      dol: "Let through", // NA from here..in case of Tested
      unit: "kA2s",
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : isNaN(rowData.letThrough)
          ? ""
          : rowData.letThrough,
    },
    {
      dol: "Value with safety factor 3",
      unit: "kA2s",
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : isNaN(rowData.safetyFactorValue)
          ? ""
          : rowData.safetyFactorValue != ""
          ? parseFloat(rowData.safetyFactorValue).toFixed(4)
          : rowData.safetyFactorValue,
    },
    {
      dol: "Contactor rated current ≥ FLA",
      unit: "",
      //data: rowData.isContactorRatedcurrent,
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : rowData.isContactorRatedcurrent == true ||
            rowData.isContactorRatedcurrent == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: "Contactor 10 sec. rating > LRA",
      unit: "",
      //data: rowData.isContactor10sRating,
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : rowData.isContactor10sRating == true ||
            rowData.isContactor10sRating == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: "Contactor let through > SCPD let through",
      unit: "",
      //data: rowData.isContactorLetThrough,
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : rowData.adminEnquiryType == 2 && rowData.typesOfCoordination == 1
          ? "NA"
          : rowData.isContactorLetThrough == true ||
            rowData.isContactorLetThrough == "Yes"
          ? "Yes"
          : "No",
    },

    // Contactor Star selection
    {
      dol: "",
      unit: "Contactor Star selection",
      data: "",
    },
    {
      dol: "MPCB Rating for Contactor Selection",
      unit: "A",
      data: parseFloat(rowData.contactorSelectionMpcb).toFixed(4),
    },
    {
      dol: "MLFB",
      unit: "",
      data: rowData.starMlfb5,
    },
    {
      dol: "Contactor Rating",
      unit: "",
      data: rowData.starContactorRating4,
    },
    {
      dol: "Selected Contactor frame Size",
      unit: "",
      data: rowData.starContactorFrameSize,
    },
    {
      dol: "Thermal rating Current for (10 sec.)", // NA for last three
      unit: "A",
      data:
        rowData.adminEnquiryType == 1 ? "NA" : rowData.starThermalRatingCurrent,
    },
    {
      dol: "Contactor rated current ≥ Star Current",
      unit: "",
      //data: rowData.isContactorGreater,
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : rowData.isContactorGreater == true ||
            rowData.isContactorGreater == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: "Contactor 10 sec. rating > LRA",
      unit: "",
      //data: rowData.isContactorGreaterLra,
      data:
        rowData.adminEnquiryType == 1
          ? "NA"
          : rowData.isContactorGreaterLra == true ||
            rowData.isContactorGreaterLra == "Yes"
          ? "Yes"
          : "No",
    },
    // Overload Relay selection
    {
      dol: "",
      unit: "Overload Relay selection",
      data: "",
    },
    {
      dol: "Condition for OLR",
      unit: "A",
      data: rowData.olrcondition,
    },
    {
      dol: "OLR rating",
      unit: "A",
      data: parseFloat(rowData.olrrating).toFixed(4),
    },
    {
      dol: "OLR range",
      unit: "",
      data: rowData.olrRange,
    },
    {
      dol: "MLFB",
      unit: "",
      data: rowData.olrmlfb,
    },
    {
      dol: "OL tripping zone time on LRA",
      unit: "Sec.",
      data: rowData.olTrippingZoneLra,
    },
    {
      dol: "LRA OL Tripping Tolerence Band 20%",
      unit: "Sec.",
      data: rowData.olrtrippingTolerance,
    },
    {
      dol: "OLR higher current ≥ Required higher current",
      unit: "",
      //data: rowData.isOlrhighercurrent,
      data:
        rowData.isOlrhighercurrent == true ||
        rowData.isOlrhighercurrent == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: "OL tripping zone time on LRA ≥ motor starting time",
      unit: "",
      //data: rowData.isOlrtztlrahigher,
      data:
        rowData.isOlrtztlrahigher == true || rowData.isOlrtztlrahigher == "Yes"
          ? "Yes"
          : "No",
    },
    {
      dol: 'IF above both conditions are "Yes" then selection is correct',
      unit: "",
      data: rowData.olrbothCondition,
    },
  ];

  const pageSizeCount = starDeltaColumns.length - 1;
  const tableHeaderStyle = {
    backgroundColor: "var(--btn-hero-bg-active)",
    color: "var(--dark-blue)",
    textAlign: "center",
  };
  const tableCellStyle = {
    textAlign: "center",
  };

  return (
    <>
      <MaterialTable
        columns={columns}
        data={startMethod === 3 ? starDeltaColumns : dolColumns}
        icons={tableIcons}
        options={{
          showFirstLastPageButtons: false,
          paging: false,
          pageSize: pageSizeCount,
          toolbar: false,
          headerStyle: tableHeaderStyle,
          cellStyle: tableCellStyle,
          sorting: false,
          rowStyle: (rowData) => {
            return {
              backgroundColor:
                rowData.dol === "" && rowData.unit !== ""
                  ? "var(--light-sand)"
                  : "",
            };
          },
        }}
      />
    </>
  );
}
