import React, { useState, useEffect } from "react";
import "./InquirySystemInput.scss";
import { TextField, Grid } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  projectNameAction,
  salesOfficeInputAction,
} from "../../Redux/Actions/actions";

export function InquirySystemInput() {
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const salesOfficeVal = useSelector((state) => state.salesOfficeInputReducer);
  const disableSysInfeedFields = useSelector(
    (state) => state.disableSystemInfeedFieldsReducer
  );
  const [inputData, setInputData] = React.useState({
    date: salesOfficeVal.date || new Date(),
    salesOffice: salesOfficeVal.salesOffice || userDetails.location,
  });

  useEffect(() => {
    console.log("inputData", inputData);
    dispatch(
      salesOfficeInputAction({
        date: inputData.date,
        salesOffice: inputData.salesOffice,
      })
    );
  }, [inputData]);

  //   const [dateError, setDateError] = useState(null);
  //   const handleDateChange = (e) => {
  //     const value = e;
  //     setDateError("");
  //     setInputData({
  //       ...inputData,
  //       date: value,
  //     });

  //     dispatch(
  //       salesOfficeInputAction({
  //         date: value,
  //         salesOffice: inputData.salesOffice,
  //       })
  //     );
  //   };
  //   function handleSalesOfficeChange(e) {
  //     const value = e.target.value;

  //     setInputData({
  //       ...inputData,
  //       [e.target.name]: value,
  //     });

  //     if (value.replace(/\s/g, "").length) {
  //       console.log("Office location", value);
  //       var trimmedValue = value.trim();
  //       dispatch(
  //         salesOfficeInputAction({
  //           date: inputData.date,
  //           salesOffice: trimmedValue,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         salesOfficeInputAction({
  //           date: inputData.date,
  //           salesOffice: "",
  //         })
  //       );
  //     }
  //   }

  //   function clearValuesAtInitial() {
  //     dispatch(salesOfficeInputAction(""));
  //     setInputData({
  //       date: new Date(),
  //       salesOffice: "",
  //     });
  //   }

  return (
    <>
      <div>
        <form>
          <Grid container spacing={2} className="componentHeaderGrid">
            <Grid item xs={12} sm={4} md={3} className="componentTitle">
              {/* <h2 className="inquiry-system-title">Inquiry System</h2> */}
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                className="mt-1"
                fullWidth
                disabled
                name="fromDate"
                type="date"
                format="dd/MM/yyyy"
                label="Date"
                variant="filled"
                // value={inputData.date}
                value={new Date(inputData.date).toISOString().substring(0, 10)}
                // onChange={handleSalesOfficeChange}
                id="date"
                size="small"
              />

              {/* <MuiPickersUtilsProvider utils={DateFnsUtils} variant={"filled"}>
                                <KeyboardDatePicker
                                    name="fromDate"
                                    margin="normal"
                                    disabled
                                    fullWidth
                                    clearable
                                    id="date-picker-dialog"
                                    label="Date"
                                    format="dd/MM/yyyy"
                                    inputVariant="filled"
                                    value={inputData.date}
                                    error={dateError && dateError.length > 0}
                                    onChange={handleDateChange}
                                    helperText={dateError}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    InputProps={{ readOnly: true }}
                                    size='small'
                                />
                            </MuiPickersUtilsProvider> */}
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                className="mt-1"
                fullWidth
                disabled
                name="salesOffice"
                type="text"
                label="Sales Office"
                variant="filled"
                value={userDetails.location}
                // onChange={handleSalesOfficeChange}
                id="salesOffice"
                size="small"
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
}
