import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AuthProvider } from "react-oidc-context";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { lightTheme } from "./Pages/Components/CommonComponents/common_components";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from "./Redux/store";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';


// document.body.style.zoom = `90%`;

// AMPS Config
//MyID Prod Start
//const oidcConfig = {
//  authority: "https://myid-qa.siemens.com:443",
//  client_id: "36dbb383-c5a6-4f95-9b3f-2ae2174668f9",
//  client_secret: "Ai8mdbTI0AHBjPhx4D",
//  redirect_uri: `${window.location.origin}`,
//  response_type: "code",
//  post_logout_redirect_uri: `${window.location.origin}`,
//  silent_redirect_uri: `${window.location.origin}`,
//  automaticSilentRenew: true,
//  requireHttpsMetadata: false,
//  getClaimsFromUserInfoEndpoint: true,
//};
//MyID Prod End

//MyID QA Start
 //const oidcConfig = {
 //  authority: "https://myid-qa.siemens.com:443",
 //    client_id: "36dbb383-c5a6-4f95-9b3f-2ae2174668f9",
 //    client_secret: "Ai8mdbTI0AHBjPhx4D",
 //  redirect_uri: `${window.location.origin}`,
 //  response_type: "code",
 //  post_logout_redirect_uri: `${window.location.origin}`,
 //  silent_redirect_uri: `${window.location.origin}`,
 //  automaticSilentRenew: true,
 //  requireHttpsMetadata: false,
 //  getClaimsFromUserInfoEndpoint: true,
//};
document.body.style.zoom = `90%`;
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (msalInstance.getActiveAccount() != null && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});
//MyID QA End
//ReactDOM.render(
//  <Provider store={store}>
//    <PersistGate loading={null} persistor={persistor}>
//      <AuthProvider {...oidcConfig}>
//        <ThemeProvider theme={lightTheme}>
//          <CssBaseline />
//          <App />
//        </ThemeProvider>
//      </AuthProvider>
//    </PersistGate>
//  </Provider>,
//  document.getElementById('root')
//);
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MsalProvider instance={msalInstance}>
                <AuthProvider>
                    <ThemeProvider theme={lightTheme}>
                        <CssBaseline />
                        <App />
                    </ThemeProvider>
                </AuthProvider>
            </MsalProvider>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
