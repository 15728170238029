import {
  authUserAction,
  storeUserDetailsAction,
  saveAccessTokenAction,
  errorMessageAction,
} from "../Redux/Actions/actions";
//import { baseApiURL } from "../Utilities/utility";
let gID,
  generatedToken = "";

// function btoaSecure(value) {
//   const { btoa } = require("abab");
//     let currDate = new Date().toDateString();
//     let valueModified = value + " " + currDate;
//     let valueSecured = btoa(btoa(valueModified));
//     return valueSecured;
// }

// function atobSecure(value) {
//   const { atob } = require("abab");
//   let arrValue = atob(atob(value)).split(" ");
//   let valueActual = arrValue[0];
//   return valueActual;
// }

const baseApiURL = window.location.origin;

export function authenticateUserBasedOnValidToken(dispatch, newToken) {
    var formdataPart1 = new FormData();
    formdataPart1.append("Token", newToken);


    const options = {
        method: "post",
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        body: formdataPart1,
    };
    return fetch(baseApiURL + "/Login/AuthenticateGId", options)
        .then((response) => response.json())
        .then((data) => {
            if (data.result.response) {
                dispatch(storeUserDetailsAction(data.result.objResult));
                return data.result;
            } else {
                return data.result;
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
            return {
                response: false,
                responseMsg: "Exception occured while authenticating user.",
            };
        });
    // const responseJson = await response.json();
    // dispatch(storeUserDetailsAction(responseJson.result));
}
//sales sqm reference

//export function authenticateUserBasedOnValidToken(dispatch, newToken, gID) {
//  const systemUser = {
//    Gid: gID,
//    //password: "password",
//  };

//  const options = {
//    method: "post",
//    mode: "cors",
//    headers: {
//      Accept: "application/json, text/plain, */*",
//      "Content-Type": "application/json",
//      "Access-Control-Allow-Credentials": true,
//      Authorization: "Bearer " + newToken,
//      //"Access-Control-Allow-Origin": "*",
//    },
//    body: JSON.stringify(systemUser),
//  };

//  return fetch(baseApiURL + "/Login/AuthenticateGId", options)
//    .then((response) => response.json())
//    .then((data) => {
//      if (data.result) {
//        dispatch(storeUserDetailsAction(data.result));
//        return true;
//      } else {
//        return false;
//      }
//    })
//    .catch((error) => {
//      return false;
//    });
//}

//export function authenticateUserBasedOnValidToken(dispatch, newToken, gID) {
//    var formdataPart1 = new FormData();
//    formdataPart1.append("Token", newToken);


//    const options = {
//        method: "post",
//        processData: false,
//        mimeType: "multipart/form-data",
//        contentType: false,
//        body: formdataPart1,
//    };

//    return fetch(baseApiURL + "/api/Login/AuthenticateGId", options)
//        .then((response) => response.json())
//        .then((data) => {
//            if (typeof data.result.response == "string") {
//                var userDataNull = {
//                    userData: {
//                        gid: "",
//                        roleId: 0,
//                        roleName: "",
//                        userEmail: "",
//                        userFirstName: "",
//                        userLastName: "",
//                        userId: 0,
//                    },
//                };
//                dispatch(storeUserDetailsAction(userDataNull));
//                return true;
//            } else if (data.result.response.roleId != null) {
//                dispatch(saveUserIdAction(data.result.uid));
//                dispatch(storeUserDetailsAction(data.result.response));
//                dispatch(saveAccessTokenAction(data.result.tokenString));
//                window.localStorage.setItem(
//                    "response",
//                    JSON.stringify(data.result.response)
//                );
//                return true;
//            } else if (data?.result?.response?.roleId == null) {
//                var userDataNull = {
//                    gid: null,
//                    roleId: null,
//                    roleName: null,
//                    userEmail: null,
//                    userFirstName: null,
//                    userLastName: null,
//                    userId: null,
//                };
//                dispatch(storeUserDetailsAction(userDataNull));
//                return false;
//            } else {
//                return false;
//            }
//        })
//        .catch((error) => {
//            return false;
//        });
//}

// We are getting ONLY token from this api
export function validateTheUserBasedOnApi(dispatch, auth) {
  const arr = auth.user.profile.sub.split("|");
  let gid = arr && arr.length ? arr[1] : "";
  const systemUser = {
    GID: gid,
  };

  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      //"Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(systemUser),
  };

  return fetch(baseApiURL + "/Login/ValidateRequest", options)
    .then((res) => res.json())
    .then((res_token) => {
      if (auth !== undefined && auth.user) {
        const arr = auth.user.profile.sub.split("|");
        gID = arr && arr.length ? arr[1] : "";
        dispatch(authUserAction(gID));
      }
      generatedToken = res_token.token;
      dispatch(saveAccessTokenAction(generatedToken));

      return generatedToken;
    })
    .catch((error) =>
      dispatch(errorMessageAction("Invalid Email or Password"))
    );
}

// generate token for forgot password
// export function generateTokenOnly(dispatch) {
//   const systemUser = {
//     userEmailId: "hiremath.basavaraj.ext@siemens.com",
//     password: "password",
//   };
//   const options = {
//     method: "post",
//     mode: "cors",
//     headers: {
//       Accept: "application/json, text/plain, */*",
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Credentials": true,
//       "Access-Control-Allow-Origin": "*",
//     },
//     body: JSON.stringify(systemUser),
//   };

//   return fetch(baseApiURL + "/Login/ValidateRequest", options)
//     .then((res) => res.json())
//     .then((res_token) => {
//       generatedToken = res_token.token;
//       dispatch(saveAccessTokenAction(generatedToken));
//       return generatedToken;
//     })
//     .catch((error) => {
//       return error;
//     }
//     );
// }

export function authenticateExternalUser(newToken, userData) {
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "Bearer " + newToken,
      //"Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(userData),
  };

  return fetch(baseApiURL + "/Login/AuthenticateExternalUser", options)
    .then((response) => response.json())
    .then((data) => {
      if (data.result) {
        return data.result;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}
export function authenticateContractUser(newToken, loginData) {
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "Bearer " + newToken,
      //"Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(loginData),
  };

  return fetch(baseApiURL + "/Login/AuthenticateContractUser", options)
    .then((response) => response.json())
    .then((data) => {
      if (data.result) {
        return data.result;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}
