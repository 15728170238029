import React, { useEffect, useState } from 'react';
import {
    TextField,
    Grid,
    Button
} from "@material-ui/core";
import './SystemInfeedForm.scss';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import * as myConstClass from "../Formula/Constants";
import * as myFunctions from "../Formula/Formula";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { contactorAction, coordinationAction, efficiencyClassAction, fuseTypeAction, infeedFormDetailsAction, motorEfficiencyAction, motorFLCAction, motorRatingAction, overloadRelayAction, scpdFuseTypeAction, startingMethodAction } from '../../Redux/Actions/actions';

export default function SystemInfeedForm() {
    const dispatch = useDispatch();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    let role = userDetails.roleId;
    const voltageVal = useSelector((state) => state.voltageDropdownReducer.voltage);
    const [motorRating, setMotorRating] = useState([]);
    const [motorRatingSelected, setMotorRatingSelected] = useState('');
    const [efficiencyType, setEfficiencyType] = useState([]);
    const [efficiencyTypeSelected, setEfficiencyTypeSelected] = useState('');
    const [startingMethod, setStartingMethod] = useState([]);
    const [startingMethodSelected, setStartingMethodSelected] = useState('');
    const [coordination, setCoordination] = useState([]);
    const [coordinationSelected, setCoordinationSelected] = useState('');
    const [fuseType, setFuseType] = useState([]);
    const [fuseTypeSelected, setFuseTypeSelected] = useState('');
    const [scpdFuseType, setScpdFuseType] = useState([]);
    const [scpdFuseTypeSelected, setScpdFuseTypeSelected] = useState('');

    const [scpdFuseLessType, setScpdFuseLessType] = useState([]);
    const [scpdFuseLessTypeSelected, setScpdFuseLessTypeSelected] = useState('');

    const [contactor, setContactor] = useState([]);
    const [contactorSelected, setContactorSelected] = useState('');
    const [overloadRelay, setOverloadRelay] = useState([]);
    const [overloadRelaySelected, setOverloadRelaySelected] = useState('');

    // for auto generated values
    const [motorEfficiency, setMotorEfficiency] = useState('');
    const [motorLRR, setMotorLRR] = useState('');
    const [motorFLC, setmotorFLC] = useState('');
    const [powerFactor, setPowerFactor] = useState('');

    // for text fields
    const [serviceFactor, setServiceFactor] = useState(1);
    const [motorStartingTimeVal, setMotorStartingTimeVal] = useState(6);
    const [coolingFanMotorCurrent, setcoolingFanMotorCurrent] = useState(0);

    // for populating data grid
    // const [infeedFormDetails, setInfeedFormDetails] = useState({
    //     motorRating: '',
    //     efficiencyClass: '',
    //     motorFLC: '',
    //     motorLRR: '',
    //     motorEfficiency: '',
    //     powerFactor: '',
    //     serviceFactor: '',
    //     motorStartingTimeVal: '',
    //     startingMethod: '',
    //     coordination: '',
    //     fuseType: '',
    //     scpdFuseType: '',
    //     contactor: '',
    //     overloadRelay: '',
    //     coolingFanMotorCurrent: ''
    // });

    // check
    var [mlfb, setMlfb] = useState(1);  //for 3RV

    const api = axios.create({
        baseURL: 'SystemInfeed'
    })

    useEffect(() => {
        // getMotorDetails();
        getMotorRating();
        getEfficiencyType();
        getStartingMethod();
        getTypeOfCoordination();
        getFuseType();
        getScpdFuseProduct();
        getScpdFuselessProduct();
        getContactor();
        getOverloadRelayProduct();

        // dispatch(infeedFormDetailsAction([]));
    }, []);

    const getMotorDetails = (motorRatingVal, efficiencyVal) => {
        let paramMotors = {};
        paramMotors = {
            "MotorRating": motorRatingVal, //id of motorRating ddl selected value
            "Efficiency": efficiencyVal, //id of efficiency ddl selected value
            "Voltage": voltageVal //id of voltage ddl selected value from previous page
        }
        api.post('/getMotorDetails', paramMotors)
            .then(function (response) {
                console.log('Motor Details', response.data);
                setMotorEfficiency(response.data.motorEfficiency);
                setMotorLRR(response.data.motorLrr);
                setmotorFLC(response.data.motorFlc);
                setPowerFactor(response.data.powerFactor);

                // setInfeedFormDetails({
                //     ...infeedFormDetails,
                //     motorEfficiency: response.data.motorEfficiency,
                //     motorLRR: response.data.motorLrr,
                //     motorFLC: response.data.motorFlc,
                //     powerFactor: response.data.powerFactor
                // });
                // dispatch(infeedFormDetailsAction(infeedFormDetails));

                if (startingMethodSelected == 1 || startingMethodSelected == 2)
                    calculateDOL(response.data.motorFlc, response.data.motorLrr);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const getMotorRating = () => {
        api.get('/getMotorRating')
            .then(res => {
                setMotorRating(res.data);
                console.log('MotorRating', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getEfficiencyType = () => {
        api.get('/getEfficiencyType')
            .then(res => {
                setEfficiencyType(res.data);
                console.log('EfficiencyType', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getStartingMethod = () => {
        api.get('/getStartingMethod')
            .then(res => {
                setStartingMethod(res.data);
                console.log('StartingMethod', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getTypeOfCoordination = () => {
        api.get('/getTypeOfCoordination')
            .then(res => {
                setCoordination(res.data);
                console.log('TypeOfCoordination', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getFuseType = () => {
        api.get('/getFuseType')
            .then(res => {
                setFuseType(res.data);
                console.log('FuseType', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getScpdFuseProduct = () => {
        api.get('/getScpdFuseProduct')
            .then(res => {
                setScpdFuseType(res.data);
                console.log('SCPDFuseProduct', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getScpdFuselessProduct = () => {
        api.get('/getScpdFuselessProduct')
            .then(res => {
                setScpdFuseLessType(res.data);
                console.log('ScpdFuselessProduct', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getContactor = () => {
        api.get('/getContactor')
            .then(res => {
                setContactor(res.data);
                console.log('Contactor', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getOverloadRelayProduct = () => {
        api.get('/getOverloadRelayProduct')
            .then(res => {
                setOverloadRelay(res.data);
                console.log('OverloadRelayProduct', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }


    /* ------ background calculation -------*/

    //var serviceFactor = 1; // get from textbox
    // var fanMotorFLC = 0;  // get from textbox
    var flaLine;
    //var motorStartingTimeVal = 6; // get from textbox
    var motorStartingTime;
    var lraContactor, lraOverload;

    var scpdDOLMinRange, scpdDOLMaxRange;
    var scpdRatingMaster, scpdRatingMinRangeMaster, scpdRatingMaxRangeMaster;
    var selectedMlfbMaster, selectedMlfbMagOnlyMaster, scpdKaMaster, scpdSizeMaster;

    var olTrippingZTLRA, olTrippingZTLRAMLFB = 0;
    var olTrippingZTLRALB, olTrippingZTLRAUB;

    var scSetting, scSettingLRA, scSettingLRALB, scSettingLRAUB;
    var letThroughOfSCPD, letThroughOfSCPDVal = 0;

    var isSCPDhighercurrent = false;
    var isOLTZTLRAhigher = false;
    var isSCLowerBand = false;
    var isMLFBCorrect = false;


    const calculateDOL = async (motorFLC, motorLrr) => {

        /***** Parameter calculation for switchgear selection B.1 *****/
        //calculate FLA
        flaLine = myFunctions.getFLALine(motorFLC, serviceFactor, coolingFanMotorCurrent);

        //calculate StartingTime 
        motorStartingTime = myFunctions.getMotorStartingTime(motorStartingTimeVal);
        setMotorStartingTimeVal(motorStartingTime.toFixed(2));

        //calculate LRA (for contactor selection)    
        lraContactor = myFunctions.getLraContactor(motorFLC, motorLrr);
        lraOverload = myFunctions.getLraOverload(motorFLC, motorLrr);

        /***** SCPD selection B.2 *****/
        //calculate SCPD - DOL min max range
        scpdDOLMinRange = myFunctions.getScpdDOLMinRange(flaLine);
        scpdDOLMaxRange = myFunctions.getScpdDOLMaxRange(flaLine);

        //scpd rating
        var selectedMlfbMasterData = await getselectedMlfbMasterData(scpdDOLMinRange, scpdDOLMaxRange);//get nearest rating data from mstMLFB table
        if (getselectedMlfbMasterData != undefined) {
            if (role != 1) //other than admin
            {
                selectedMlfbMasterData = selectedMlfbMasterData.find(({ category }) => category === 'Tested');
                if (selectedMlfbMasterData != undefined) {
                    scpdRatingMaster = selectedMlfbMasterData.rating;//get rating from mstMLFB table
                    scpdRatingMinRangeMaster = selectedMlfbMasterData.minRange;//get min range based on above row get nearest range from mstMLFB table
                    scpdRatingMaxRangeMaster = selectedMlfbMasterData.maxRange;//get max range based on above row get nearest range from mstMLFB table
                    selectedMlfbMaster = selectedMlfbMasterData.standardRelease;//get std release mlfb from mstMLFB table, for admin it will get 2 and for other user it will get 1 value
                    selectedMlfbMagOnlyMaster = selectedMlfbMasterData.magOnly;//get mlfbMagOnly from mstMLFB table, for admin it will get 2 and for other user it will get 1 value
                    scpdKaMaster = selectedMlfbMasterData.voltKa; //get ka from mstMLFB table
                    scpdSizeMaster = selectedMlfbMasterData.size; //get size from mstMLFB table


                    //OL tripping zone time on LRA
                    var allMlfbProductData = await getMlfbProductData(selectedMlfbMaster, flaLine);//get all 3poleLoadFactor values and its ta from mstMLFBProductDetail
                    if (allMlfbProductData != undefined && allMlfbProductData.length > 0) {
                        var olTrippingZTLRA = allMlfbProductData.find(({ threePoleFactor }) => threePoleFactor >= lraOverload);

                        if (olTrippingZTLRA != undefined)
                            olTrippingZTLRAMLFB = olTrippingZTLRA.threePoleTa;// get its ta

                        //calculate Lower bound and upper bound of OL tripping zone time on LRA
                        olTrippingZTLRALB = myFunctions.getOlTrippingZTLRALB(olTrippingZTLRAMLFB);
                        olTrippingZTLRAUB = myFunctions.getOlTrippingZTLRAUB(olTrippingZTLRAMLFB);

                        //calculate SC zone based on LRA
                        //SC setting  things[0].event
                        scSetting = allMlfbProductData[0].shortCircuitReleaseFactor; //take always highest value
                        scSettingLRA = myFunctions.getScSettingLRA(scpdRatingMaxRangeMaster, scSetting);
                        //calculate Lower bound and upper bound of SC zone based on LRA
                        scSettingLRALB = myFunctions.getScSettingLRALB(scSettingLRA);
                        scSettingLRAUB = myFunctions.getScSettingLRAUB(scSettingLRA);

                        //Let through of SCPD required S/C (sr. no. 6)
                        letThroughOfSCPD = allMlfbProductData.sort(function (a, b) {
                            return Math.abs(a._400vI2tCharactersticIk - scpdKaMaster) - Math.abs(b._400vI2tCharactersticIk - scpdKaMaster)
                        })[0];
                        if (letThroughOfSCPD != undefined)
                            letThroughOfSCPDVal = letThroughOfSCPD._400vI2tCharactersticI2t;
                    }
                }
            }
            else {
                //for admin
                console.log('selectedMlfbMasterData', selectedMlfbMasterData);
            }
        }


        //Condition to verify
        if (scpdRatingMaxRangeMaster >= scpdDOLMaxRange)
            isSCPDhighercurrent = true;

        if (olTrippingZTLRALB >= motorStartingTime)
            isOLTZTLRAhigher = true;

        if (scSettingLRALB > (myConstClass.scLowerBandFactor * (lraContactor)))
            isSCLowerBand = true;

        if (isSCPDhighercurrent == true && isOLTZTLRAhigher == true && isSCLowerBand == true)
            isMLFBCorrect = true;
        console.log('Mlfb', isMLFBCorrect);

        /***** Contactor selection B.3  *****/
        if (contactor != null || contactor != undefined)
            calculateContactor(flaLine, lraContactor, letThroughOfSCPDVal);

        /****** ----- Overload Relay selection ----- ******/
        if (overloadRelay != null || overloadRelay != undefined)
            calculateOLR(flaLine, motorStartingTime, lraOverload);
    }

    /***** Contactor selection B.3  *****/
    const calculateContactor = async (flaLine, lraContactor, letThroughOfSCPDVal) => {

        var contactorRatingMaster, contactorSiriusRatingMaster, selectedContactorMaster, contactorSizeMaster;
        var thermalRatingMaster;
        var letThroughOfContactor, letThroughOfContactorSafetyFac;
        var isContactorRatedcurrent = false;
        var isContactor10sRating = false;
        var isContactorLetThrough = false;

        //get data from Sirius chart by flaline
        var siriusChartContactorMasterData = await getSiriusChartContactorMaster(flaLine);
        if (siriusChartContactorMasterData != undefined) {
            contactorSiriusRatingMaster = siriusChartContactorMasterData.mpcbrating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaLine variable)
            contactorRatingMaster = siriusChartContactorMasterData.contactorRating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaLine variable)
            selectedContactorMaster = siriusChartContactorMasterData.mlfb; // get contactor from Sirius chart 
            contactorSizeMaster = siriusChartContactorMasterData.contactorSize; // get size from Sirius chart 

            //get Thermal Rating from ContactorMaster by contactorRatingMaster variable value
            var contactorRatingMasterData = await getContactorRatingMaster(contactorRatingMaster);
            if (contactorRatingMasterData != undefined) {
                thermalRatingMaster = contactorRatingMasterData.thermalLoading10s; //get 10S (as per req voltage) column value from contactorMaster 
                letThroughOfContactor = (thermalRatingMaster * thermalRatingMaster * myConstClass.letThroughOfContactorThermalRatingFactor) / myConstClass.letThroughOfContactorFactor;
                letThroughOfContactorSafetyFac = myFunctions.geLetThroughOfContactorSafetyFac(letThroughOfContactor);
            }
        }


        //Condition to verify
        if (contactorRatingMaster > flaLine)
            isContactorRatedcurrent = true;

        if (thermalRatingMaster > lraContactor)
            isContactor10sRating = true;

        if (letThroughOfContactorSafetyFac > letThroughOfSCPDVal)
            isContactorLetThrough = true;
        console.log('Contactor', isContactorLetThrough)
    }

    /****** ----- Overload Relay selection ----- ******/
    const calculateOLR = async (flaLine, motorStartingTime, lraOverload) => {
        var OLRMinRange, OLRMaxRange;
        var OLRRatingMaster, OLRMinRangeMaster, OLRMaxRangeMaster, selectedOLRMlfbMaster;
        var olTrippingZTLRAOLR = 0, olTrippingZTLRALBOLR, olTrippingZTLRAUBOLR;

        var isOLRhighercurrent = false;
        var isOLRTZTLRAhigher = false;
        var isOLRCorrect = false;

        //Condition for OLR
        OLRMinRange = myFunctions.getOLRMinRange(flaLine);
        OLRMaxRange = myFunctions.getOLRMaxRange(flaLine);

        //get relay size from Sirius chart
        var siriusChartOLRSize = await getSiriusChartOLRMaster(OLRMinRange, OLRMaxRange);

        //get above sirius chart size data of selected range from overload relay master
        var selectedOLRMlfbMasterData = await getSelectedOLRMlfbMaster(OLRMinRange, OLRMaxRange, siriusChartOLRSize);


        selectedOLRMlfbMasterData = selectedOLRMlfbMasterData.find(({ size }) => size === 'S0');

        OLRMinRangeMaster = selectedOLRMlfbMasterData.minRange;//get min range based on above row get nearest range from 3RURelay table
        OLRMaxRangeMaster = selectedOLRMlfbMasterData.maxRange;//get max range based on above row get nearest range from 3RURelay table
        selectedOLRMlfbMaster = selectedOLRMlfbMasterData.mlfb;//get mlfb from 3RURelay table
        OLRRatingMaster = selectedOLRMlfbMasterData.rating;//get rating from 3RURelay master table

        //OL tripping zone time on LRA
        var get3PoleLFOLRData = await getOverloadRelayProductDetail(selectedOLRMlfbMaster, flaLine); //get all (3poleLoadFactor *flaline) values and its ta from mstRURelay master table

        var newPoleLFOLR = get3PoleLFOLRData.find(({ threePoleFactor }) => threePoleFactor >= lraOverload);

        if (newPoleLFOLR != undefined)
            olTrippingZTLRAOLR = newPoleLFOLR.threePoleTa;// get values greater than lraOverload and get its ta

        //calculate Lower bound and upper bound of OL tripping zone time on LRA 
        olTrippingZTLRALBOLR = myFunctions.getOlTrippingZTLRALBOLR(olTrippingZTLRAOLR);
        olTrippingZTLRAUBOLR = myFunctions.getOlTrippingZTLRAUBOLR(olTrippingZTLRAOLR);



        //Condition to verify
        if (OLRMaxRangeMaster >= OLRMaxRange)
            isOLRhighercurrent = true;

        if (olTrippingZTLRALBOLR >= motorStartingTime)
            isOLRTZTLRAhigher = true;

        if (isOLRhighercurrent == true && isOLRTZTLRAhigher == true)
            isOLRCorrect = true;
        console.log('OverloadRelay', isOLRCorrect);
    }
    /**** ------- ****/

    /**** ------- ****/
    //get MLFB Master data
    const getselectedMlfbMasterData = async (scpdDOLMinRange, scpdDOLMaxRange) => {
        let paramMlfb = {};
        paramMlfb = {
            "MinRange": scpdDOLMinRange,
            "MaxRange": scpdDOLMaxRange,
            "ProductGroup": mlfb,
            "FuseType": fuseType,
            "Voltage": voltageVal
        }
        const res = await api.post('/getselectedMlfbMasterData', paramMlfb)
        return await res.data;
    }
    //get MLFB Product Master data
    const getMlfbProductData = async (selectedMlfbMaster, flaLine) => {
        let paramMlfb = {};
        paramMlfb = {
            "ProductName": selectedMlfbMaster,
            "FlaLine": flaLine
        }
        const res = await api.post('/getMlfbProductData', paramMlfb)
        return await res.data;
    }

    //get Overload Relay Master data
    const getSelectedOLRMlfbMaster = async (OLRMinRange, OLRMaxRange, siriusChartOLRSize) => {
        let paramOLRMlfb = {};
        paramOLRMlfb = {
            "MinRange": OLRMinRange,
            "MaxRange": OLRMaxRange,
            "ProductGroup": overloadRelay,
            "Size": siriusChartOLRSize
        }
        const res = await api.post('/getSelectedOLRMlfbMaster', paramOLRMlfb)
        return await res.data;
    }
    //get Overload Relay Product Master data
    const getOverloadRelayProductDetail = async (selectedOLRMlfbMaster, flaLine) => {
        let param3PoleLFOLR = {};
        param3PoleLFOLR = {
            "ProductName": selectedOLRMlfbMaster,
            "FlaLine": flaLine
        }
        const res = await api.post('/getOverloadRelayProductDetail', param3PoleLFOLR)
        return await res.data;
    }
    const getSiriusChartOLRMaster = async (OLRMinRange, OLRMaxRange) => {
        let paramContactor = {};
        paramContactor = {
            "MinRange": OLRMinRange,
            "MaxRange": OLRMaxRange,
            "Voltage": voltageVal,
            "TypeOfCordination": coordination
        }
        const res = await api.post('/getSiriusChartOLRMaster', paramContactor)
        return await res.data;
    }



    //get Contactor Master data    
    const getSiriusChartContactorMaster = async (flaLine) => {
        let paramContactor = {};
        paramContactor = {
            "FlaLine": flaLine,
            "Voltage": voltageVal,
            "TypeOfCordination": coordination
        }
        const res = await api.post('/getSiriusChartContactorMaster', paramContactor)
        return await res.data;
    }
    const getContactorRatingMaster = async (contactorRatingMaster) => {
        let paramContactor = {};
        paramContactor = {
            "ProductId": contactor,
            "Voltage": voltageVal,
            "Rating": contactorRatingMaster
        }
        const res = await api.post('/getContactorRatingMaster', paramContactor)
        return await res.data;
    }


    /******** ----------- *********/



    function handleMotorRatingChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setMotorRatingSelected(value);
        // setInfeedFormDetails({
        //     ...infeedFormDetails,
        //     motorRating: value
        // });
        // dispatch(infeedFormDetailsAction(infeedFormDetails));
        if (efficiencyTypeSelected !== '' &&
            value !== '' &&
            voltageVal !== undefined) {
            getMotorDetails(value, efficiencyTypeSelected);
        }
    }
    function handleEfficiencyFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setEfficiencyTypeSelected(value);
        // setInfeedFormDetails({
        //     ...infeedFormDetails,
        //     efficiencyClass: value
        // });
        // dispatch(infeedFormDetailsAction(infeedFormDetails));
        if (value !== '' &&
            motorRatingSelected !== '' &&
            voltageVal !== undefined) {
            getMotorDetails(motorRatingSelected, value);
        }
    }
    function handleStartingMethodFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setStartingMethodSelected(value);
        // setInfeedFormDetails({
        //     ...infeedFormDetails,
        //     startingMethod: value
        // });
        // dispatch(infeedFormDetailsAction(infeedFormDetails));
        if ((value === 1 || value === 2) && motorFLC !== '' && motorLRR !== '')
            calculateDOL(motorFLC, motorLRR);

    }
    function handleCoordinationFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setCoordinationSelected(value);
        // setInfeedFormDetails({
        //     ...infeedFormDetails,
        //     coordination: value
        // });
        // dispatch(infeedFormDetailsAction(infeedFormDetails));
    }
    function handleFuseTypeFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setFuseTypeSelected(value);
        // setInfeedFormDetails({
        //     ...infeedFormDetails,
        //     fuseType: value
        // });
        // dispatch(infeedFormDetailsAction(infeedFormDetails));
        console.log('fuse', value);
    }
    function handleScpdFuseTypeFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        if (fuseTypeSelected == 1) {
            setScpdFuseTypeSelected(value);
            // setInfeedFormDetails({
            //     ...infeedFormDetails,
            //     scpdFuseType: value
            // });
            // dispatch(infeedFormDetailsAction(infeedFormDetails));
        }
        else {
            setScpdFuseLessTypeSelected(value);
            // setInfeedFormDetails({
            //     ...infeedFormDetails,
            //     scpdFuseType: value
            // });
            // dispatch(infeedFormDetailsAction(infeedFormDetails));
        }
    }
    function handleContactorFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setContactorSelected(value);
        // setInfeedFormDetails({
        //     ...infeedFormDetails,
        //     contactor: value
        // });
        // dispatch(infeedFormDetailsAction(infeedFormDetails));
    }
    function handleOverloadRelayFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setOverloadRelaySelected(value);
        // setInfeedFormDetails({
        //     ...infeedFormDetails,
        //     overloadRelay: value
        // });
        // dispatch(infeedFormDetailsAction(infeedFormDetails));
    }
    function serviceFactorChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setServiceFactor(value);
        // setInfeedFormDetails({
        //     ...infeedFormDetails,
        //     serviceFactor: value
        // });
        // dispatch(infeedFormDetailsAction(infeedFormDetails));
    }
    function motorStartingTimeChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setMotorStartingTimeVal(value);
        // setInfeedFormDetails({
        //     ...infeedFormDetails,
        //     motorStartingTimeVal: value
        // });
        // dispatch(infeedFormDetailsAction(infeedFormDetails));
    }
    function coolingFanMotorCurrentChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setcoolingFanMotorCurrent(value);
        // setInfeedFormDetails({
        //     ...infeedFormDetails,
        //     coolingFanMotorCurrent: value
        // });
        // dispatch(infeedFormDetailsAction(infeedFormDetails));
    }

    const handleClearDetails = () => {
        console.log('clear');
        setMotorRatingSelected('');
        setEfficiencyTypeSelected('');
        setmotorFLC('');
        setMotorLRR('');
        setMotorEfficiency('');
        setPowerFactor('');
        setMotorStartingTimeVal(6);
        setStartingMethodSelected('');
        setCoordinationSelected('');
        setFuseTypeSelected('');
        setScpdFuseTypeSelected('');
        setScpdFuseLessTypeSelected('');
        setContactorSelected('');
        setOverloadRelaySelected('');
        setcoolingFanMotorCurrent(0);

        // dispatch(motorRatingAction());
        // dispatch(motorEfficiencyAction());
        // dispatch(motorFLCAction());
        // dispatch(efficiencyClassAction());
        // dispatch(startingMethodAction());
        // dispatch(coordinationAction());
        // dispatch(fuseTypeAction());
        // dispatch(scpdFuseTypeAction());
        // dispatch(contactorAction());
        // dispatch(overloadRelayAction());

    }
    const handleSaveClick = () => {
        dispatch(motorRatingAction(motorRatingSelected));
        dispatch(motorEfficiencyAction(motorEfficiency));
        dispatch(motorFLCAction(motorFLC));
        dispatch(efficiencyClassAction(efficiencyTypeSelected));
        dispatch(startingMethodAction(startingMethodSelected));
        dispatch(coordinationAction(coordinationSelected));
        dispatch(fuseTypeAction(fuseTypeSelected));
        if (fuseTypeSelected == 1) {
            dispatch(scpdFuseTypeAction(scpdFuseTypeSelected));
        }
        else {
            dispatch(scpdFuseTypeAction(scpdFuseLessTypeSelected));
        }
        // dispatch(scpdFuseTypeAction(scpdFuseTypeSelected));
        dispatch(contactorAction(contactorSelected));
        dispatch(overloadRelayAction(overloadRelaySelected));
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant='filled'>
                        <InputLabel id="motorRating-label">Motor Rating (kW)</InputLabel>
                        <Select
                            name='motorRating'
                            label='demo-simple-select-helper-label'
                            id="motorRating"
                            value={motorRatingSelected}
                            onChange={handleMotorRatingChange}
                        >
                            {motorRating.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.motorRating}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant='filled'>
                        <InputLabel id="efficiency-label">Efficiency Class</InputLabel>
                        <Select
                            name='efficiencyClass'
                            label='demo-simple-select-helper-label'
                            id="efficiencyClass"
                            value={efficiencyTypeSelected}
                            onChange={handleEfficiencyFieldChange}
                        >
                            {efficiencyType.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.efficiencyType}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={4} md={3} className='d-flex jc-center'>
                    <Button
                        // disabled={activeStep === 0}
                        onClick={handleClearDetails}
                        className="pt-button--secondary"
                    >
                        Clear Details
                    </Button>
                </Grid> */}
            </Grid>


            <hr className="dashed"></hr>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="motorFLC"
                        type="text"
                        label="Motor FLC (A)"
                        variant="filled"
                        value={motorFLC}
                        // onChange={handleFieldChange}
                        id="motorFLC"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="motorLRR"
                        type="text"
                        label="Motor LRR"
                        variant="filled"
                        value={motorLRR}
                        // onChange={handleFieldChange}
                        id="motorLRR"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="motorEfficiency"
                        type="text"
                        label="Motor Efficiency (&#951;)"
                        variant="filled"
                        value={motorEfficiency}
                        // onChange={handleFieldChange}
                        id="motorEfficiency"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="powerFactor"
                        type="text"
                        label="Power Factor (cos &#934;)"
                        variant="filled"
                        value={powerFactor}
                        // onChange={handleFieldChange}
                        id="powerFactor"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="serviceFactor"
                        type="text"
                        label="Motor Service Factor"
                        variant="filled"
                        value={serviceFactor}
                        onChange={serviceFactorChange}
                        id="serviceFactor"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="motorStartingTimeVal"
                        type="text"
                        label="Motor Starting Time (sec)"
                        variant="filled"
                        value={motorStartingTimeVal}
                        onChange={motorStartingTimeChange}
                        id="motorStartingTimeVal"
                    />
                </Grid>
            </Grid>
            <hr className="dashed"></hr>


            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant='filled'>
                        <InputLabel id="efficiency-label">Starting Method</InputLabel>
                        <Select
                            name='startingMethod'
                            label='demo-simple-select-helper-label'
                            id="startingMethod"
                            value={startingMethodSelected}
                            onChange={handleStartingMethodFieldChange}
                        >
                            {startingMethod.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.method}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant='filled'>
                        <InputLabel id="efficiency-label">Types of Co-ordination</InputLabel>
                        <Select
                            name='coordination'
                            label='demo-simple-select-helper-label'
                            id="coordination"
                            value={coordinationSelected}
                            onChange={handleCoordinationFieldChange}
                        >
                            {coordination.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.coOrdinationType}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant='filled'>
                        <InputLabel id="efficiency-label">Fuse Type</InputLabel>
                        <Select
                            name='fuseType'
                            label='demo-simple-select-helper-label'
                            id="fuseType"
                            value={fuseTypeSelected}
                            onChange={handleFuseTypeFieldChange}
                        >
                            {fuseType.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.fuseType}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant='filled'>
                        <InputLabel id="efficiency-label">SCPD</InputLabel>
                        <Select
                            name='scpdFuseType'
                            label='demo-simple-select-helper-label'
                            id="scpdFuseType"
                            value={fuseTypeSelected == 1 ? scpdFuseTypeSelected : scpdFuseLessTypeSelected}
                            onChange={handleScpdFuseTypeFieldChange}
                        >
                            {
                                fuseTypeSelected == 1 ? (scpdFuseType.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                        {item.productType}
                                    </MenuItem>
                                ))) : ((scpdFuseLessType.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                        {item.productType}
                                    </MenuItem>
                                ))))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant='filled'>
                        <InputLabel id="efficiency-label">Contactor</InputLabel>
                        <Select
                            name='contactor'
                            label='demo-simple-select-helper-label'
                            id="contactor"
                            value={contactorSelected}
                            onChange={handleContactorFieldChange}
                        >
                            {contactor.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.productType}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant='filled'>
                        <InputLabel id="efficiency-label">Overload Relay</InputLabel>
                        <Select
                            name='overloadRelay'
                            label='demo-simple-select-helper-label'
                            id="overloadRelay"
                            value={overloadRelaySelected}
                            onChange={handleOverloadRelayFieldChange}
                        >
                            {overloadRelay.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.productType}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="coolingFanMotorCurrent"
                        type="text"
                        label="Fan Motor FLC (A)"
                        variant="filled"
                        value={coolingFanMotorCurrent}
                        onChange={coolingFanMotorCurrentChange}
                        id="coolingFanMotorCurrent"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} className='jc-center d-flex mt-1'>
                <Grid item xs={10} sm={6} md={2}>
                    <Button
                        fullWidth
                        onClick={handleClearDetails}
                        className="pt-button--secondary mt-1"
                    >
                        Clear
                    </Button>
                </Grid>
                <Grid item xs={10} sm={6} md={2}>
                    <Button
                        fullWidth
                        onClick={handleSaveClick}
                        className="pt-button--primary mt-1"
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}