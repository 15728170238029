import MaterialTable from "material-table";
import { useEffect } from "react";
import { tableIcons } from '../../Utilities/utility';

export function InfoiconDraftDialog(props) {
    const rowData = props.selectedRowData;

    useEffect(() => {
        console.log("rowData", rowData);
    });

    const DraftData = [
        {
            "id": 11219,
            "userId": 33,
            "systemInfeedId": 11241,
            "motorRating": 11,
            "efficiency": 0.84,
            "motorFlc": 2.98,
            "motorLrr": 6,
            "class": "1",
            "startingMethod": 1,
            "typesOfCoordination": 2,
            "fuseType": 2,
            "scpd": 1,
            "contactor": 1,
            "overloadRelay": 2,
            "coolingFanMotorCurrent": "0",
            "powerFactor": "0.79",
            "serviceFactor": "1",
            "adminEnquiryType": 1,
            "mlfbMasterData": "3RV2011-1DA10",
            "mlfbMasterRatingData": "3.2",
            "siriusChartContactorData": "3RT2015",
            "siriusChartContactorRatingData": "7",
            "olrMlfbData": "3RU2116-1DB0",
            "olrMlfbRatingData": "3.2",
            "flaline": "2.9838",
            "flaphase": "",
            "motorStartingTime": "4.800000000000001",
            "lra": "",
            "lraForContactor": "21.483359999999998",
            "lraForOlselection": "17.9028",
            "starCurrent": "",
            "flalinetolerance": "2.83461 - 3.13299",
            "scpdratingCurrent": "3.2",
            "scpdmlfb": "3RV2011-1DA10",
            "scpdsize": "S00",
            "scpdka": 100,
            "scfactor": "13",
            "lraSc": "41.6",
            "lraSctolerance": "33.28 - 49.92",
            "scpdletThrough": "2.1102573",
            "isScpdhighercurrent": true,
            "isOltztlrahigher": true,
            "isSclowerBand": true,
            "scpdbothCondition": "Correct",
            "contactorSiriusRatingMaster": "4",
            "contactorMlfb": "3RT2015",
            "contactorRating": 7,
            "contactorFrameSize": "S00",
            "thermalRatingCurrent": 56,
            "letThrough": "31.36",
            "safetyFactorValue": "10.453333333333333",
            "isContactorRatedcurrent": true,
            "isContactor10sRating": true,
            "isContactorLetThrough": true,
            "contactorSelectionMpcb": "",
            "starMlfb": "",
            "starContactorRating": "",
            "starContactorFrameSize": "",
            "isContactorGreater": "",
            "isContactorGreaterLra": "",
            "olrcondition": "2.83461-3.13299",
            "olrrating": "3.2",
            "olrRange": "2.2-3.2",
            "olrmlfb": "3RU2116-1DB0",
            "olTrippingZoneLra": "8.4491",
            "olrtrippingTolerance": "6.7592799999999995-10.138919999999999",
            "isOlrhighercurrent": true,
            "isOlrtztlrahigher": true,
            "olrbothCondition": "Correct",
            "status": null,
            "createdDate": "2022-03-04T11:03:15.723",
            "modifiedDate": null,
            "createdBy": 33,
            "modifiedBy": null,
            "isActive": true
        }
        //{
        //     'dol': 'SCPD Current Range',
        //     'unit': '',
        //     'data': '6.3'
        //},

    ];

    function motorRatingValueConverter(motorRatingData) {
        switch (motorRatingData) {
            case 1: {
                return '0.04';
            }
            case 2: {
                return '0.06'
            }
            case 3: {
                return '0.09'
            }
            case 4: {
                return '0.12'
            }
            case 5: {
                return '0.18'
            }
            case 6: {
                return '0.25'
            }
            case 7: {
                return '0.37'
            }
            case 8: {
                return '0.55'
            }
            case 9: {
                return '0.75'
            }
            case 10: {
                return '1.1'
            }
            case 11: {
                return '1.5'
            }
            case 12: {
                return '2.2'
            }
            case 13: {
                return '3'
            }
            case 14: {
                return '3.7'
            }
            case 15: {
                return '4'
            }
            case 16: {
                return '5.5'
            }
            case 17: {
                return '7.5'
            }
            case 18: {
                return '9.3'
            }
            case 19: {
                return '11'
            }
            case 20: {
                return '15'
            }
            case 21: {
                return '18.5'
            }
            case 22: {
                return '22'
            }
            case 23: {
                return '30'
            }
            case 24: {
                return '37'
            }
            case 25: {
                return '45'
            }
            case 26: {
                return '55'
            }
            case 27: {
                return '75'
            }
            case 28: {
                return '90'
            }
            case 29: {
                return '110'
            }
            case 30: {
                return '132'
            }
            case 31: {
                return '160'
            }
            case 32: {
                return '200'
            }
            case 33: {
                return '250'
            }
            default: {
                return 0
            }

        }
    }
    function efficiencyClassValueConverter(efficiencyClassData) {
        switch (efficiencyClassData) {
            case 1: {
                return "IE2";
            }
            case 2: {
                return "IE3"
            }
            case 3: {
                return "IE4"
            }
            default: {
                return ''
            }
        }
    }
    function startingMethodValueConverter(startingMethodData) {
        switch (startingMethodData) {
            case 1: {
                return "DOL";
            }
            case 2: {
                return "RDOL"
            }
            case 3: {
                return "SD"
            }
            default: {
                return ''
            }

        }
    }
    function coordinationValueConverter(coordinationData) {
        switch (coordinationData) {
            case 1: {
                return "Type-1";
            }
            case 2: {
                return "Type-2"
            }
            default: {
                return ''
            }

        }
    }
    function fuseTypeValueConverter(fuseTypeData) {
        switch (fuseTypeData) {
            case 1: {
                return "Fuse";
            }
            case 2: {
                return "Fuseless"
            }
            default: {
                return ''
            }

        }
    }
    function scpdFuseValueConverter(scpdFuseTypeData) {
        if (scpdFuseTypeData == 1) {
            switch (scpdFuseTypeData) {
                case 1: {
                    return "3NA";
                }
                default: {
                    return ''
                }
            }
        }
        else {
            switch (scpdFuseTypeData) {
                case 1: {
                    return "3RV";
                }
                case 2: {
                    return "3VS";
                }
                case 3: {
                    return "3VA";
                }
                case 4: {
                    return "3VM";
                }
                default: {
                    return ''
                }
            }
        }
    }
    function contactorValueConverter(contactorData) {
        switch (contactorData) {
            case 1: {
                return "3RT";
            }
            case 2: {
                return "3TF"
            }
            case 3: {
                return "3TS"
            }
            default: {
                return ''
            }

        }
    }
    function overloadRelayValueConverter(overloadRelayData) {
        switch (overloadRelayData) {
            case 1: {
                return "3RU";
            }
            case 2: {
                return "3RB"
            }
            case 3: {
                return "3UA"
            }
            case 4: {
                return "3US"
            }
            default: {
                return ''
            }
        }
    }

    const columns = [
        { title: 'Field', field: 'field' },
        { title: 'Data', field: 'data' }
    ];

    const matTableData = [
        {
            'field': 'Motor Rating (kW)',
            'data': motorRatingValueConverter(rowData.configurationRequests[0].motorRating)
        },
        {
            'field': 'Motor Efficiency',
            'data': rowData.configurationRequests[0].efficiency
        },
        {
            'field': 'FLC (A)',
            'data': rowData.configurationRequests[0].motorFlc
        },
        {
            'field': 'Class',
            'data': efficiencyClassValueConverter(Number(rowData.configurationRequests[0].class))
        },
        {
            'field': 'Starting Method',
            'data': startingMethodValueConverter(rowData.configurationRequests[0].startingMethod)
        },
        {
            'field': 'Coordination',
            'data': coordinationValueConverter(rowData.configurationRequests[0].contactor)
        },
        {
            'field': 'Types',
            'data': fuseTypeValueConverter(rowData.configurationRequests[0].typesOfCoordination)
        },
        {
            'field': 'SCPD',
            'data': scpdFuseValueConverter(rowData.configurationRequests[0].scpd)
        },
        {
            'field': 'Contactor',
            'data': contactorValueConverter(rowData.configurationRequests[0].contactor)
        },
        {
            'field': 'OLRelay',
            'data': overloadRelayValueConverter(rowData.configurationRequests[0].overloadRelay)
        },
    ];

    //const pageSizeCount = starDeltaColumns.length - 1;
    const tableHeaderStyle = {
        backgroundColor: "var(--btn-hero-bg-active)",
        color: "var(--dark-blue)",
        textAlign: 'center'
    };
    const tableCellStyle = {
        textAlign: 'center'
    };


    return (
        <>
            <MaterialTable
                columns={columns}
                data={matTableData}
                icons={tableIcons}
                options={{
                    showFirstLastPageButtons: false,
                    paging: false,
                    //pageSize: pageSizeCount,
                    toolbar: false,
                    headerStyle: tableHeaderStyle,
                    cellStyle: tableCellStyle,
                    sorting: false,
                    //rowStyle: (rowData) => {
                    //    return {
                    //        backgroundColor: (rowData.dol === '' && rowData.unit !== '') ? "var(--light-sand)" : '',
                    //    };
                    /*},*/

                }}
            />
        </>
    )
}