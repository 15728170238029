import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import DetailedReport from "./DetailedReport";
import SummaryReport from "./SummaryReport";
import { makeStyles } from "@material-ui/core";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function Reports() {
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div style={{ textAlign: "center" }}><h2>Reports</h2></div>
            <div className={classes.root}>
                <AppBar position="static" className="custAppBar">
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Summary Report" />
                        <Tab label="Detailed Report" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0} className="custTabPanel">
                    <SummaryReport />
                </TabPanel>
                <TabPanel value={value} index={1} className="custTabPanel">
                    <DetailedReport />
                </TabPanel>
            </div>
        </>
    );
}