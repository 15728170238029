import React from "react";
import "./SystemInfeed.scss";
import { TextField, Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  salesEngineerAction,
  projectNameAction,
  customerNameAction,
  endCustomerAction,
  voltageDropdownAction,
  frequencyDropdownAction,
  faultLevelDropdownAction,
} from "../../Redux/Actions/actions";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
}));

export function SystemInfeed() {
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const draftActiveData = useSelector((state) => state.draftActiveStepReducer);
  const dispatch = useDispatch();
  const classes = useStyles();

  const projectNameVal = useSelector(
    (state) => state.projectNameReducer.projectName
  );
  const custNameVal = useSelector(
    (state) => state.customerNameReducer.customerName
  );
  const endCustVal = useSelector(
    (state) => state.endCustomerReducer.endCustomer
  );
  const voltageVal = useSelector(
    (state) => state.voltageDropdownReducer.voltage
  );
  const frequencyVal = useSelector(
    (state) => state.frequencyDropdownReducer.frequency
  );
  const faultLevelVal = useSelector(
    (state) => state.faultLevelDropdownReducer.faultLevel
  );
  const disableSysInfeedFields = useSelector(
    (state) => state.disableSystemInfeedFieldsReducer
  );

  const [voltage, setVoltage] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [faultLevel, setFaultLevel] = useState([]);

  const [infeedData, setInfeedData] = useState({
    salesEngineer: userDetails.firstName,
    projectName: projectNameVal || "",
    customerName: custNameVal || "",
    endCustomer: endCustVal || "",
    voltage: voltageVal || "",
    frequency: frequencyVal || "",
    faultLevel: faultLevelVal || "",
  });

  // const [salesEngineer, setSalesEngineer] = useState(userDetails.firstName);
  // const [projectName, setProjectName] = useState('');
  // const [customerName, setCustomerName] = useState('');
  // const [endCustomer, setEndCustomer] = useState('');

  function handleTextFieldChange(e) {
    let value = e.target.value;
    let field = e.target.name;
    setInfeedData({
      ...infeedData,
      [field]: value,
    });
    console.log("EnginneringVal", infeedData);
    if (value.replace(/\s/g, "").length) {
      var trimmedValue = value.trim();
      switch (field) {
        case "salesEngineer":
          // setSalesEngineer(value);
          dispatch(salesEngineerAction(trimmedValue));
          return;
        case "projectName":
          // setProjectName(value);
          dispatch(projectNameAction(trimmedValue));
          return;
        case "customerName":
          // setCustomerName(value);
          dispatch(customerNameAction(trimmedValue));
          return;
        case "endCustomer":
          // setEndCustomer(value);
          dispatch(endCustomerAction(trimmedValue));
          return;
      }
    } else {
      switch (field) {
        case "salesEngineer":
          dispatch(salesEngineerAction());
          return;
        case "projectName":
          dispatch(projectNameAction());
          return;
        case "customerName":
          dispatch(customerNameAction());
          return;
        case "endCustomer":
          dispatch(endCustomerAction());
          return;
      }
    }
  }

  function handleFieldChange(e, key) {
    const selectedItemID = e.target.value;
    const field = e.target.name;
    const selectedItemValue = key.props.children;

    const selectedItemKeyValue = {
      selectedItemID: selectedItemID,
      selectedItemValue: selectedItemValue,
    };
    setInfeedData({
      ...infeedData,
      [e.target.name]: selectedItemID,
    });
    switch (field) {
      case "voltage":
        dispatch(voltageDropdownAction(selectedItemKeyValue));
        return;
      case "frequency":
        dispatch(frequencyDropdownAction(selectedItemKeyValue));
        return;
      case "faultLevel":
        dispatch(faultLevelDropdownAction(selectedItemKeyValue));
        return;
    }
  }

  const api = axios.create({
    baseURL: "InquirySystem",
  });
  const getDropdownValues = () => {
    getAllVoltage();
    getAllFaultLevel();
    getAllFrequency();
  };
  useEffect(() => {
    getDropdownValues();
  }, []);

  const getAllVoltage = () => {
    api
      .get("/getAllVoltage")
      .then((res) => {
        console.log(res.data);
        setVoltage(res.data);
        // setInfeedData({
        //     ...infeedData,
        //     voltage: res.data,
        // });
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.", error);
      });
  };

  const getAllFaultLevel = () => {
    api
      .get("/getAllFaultLevel")
      .then((res) => {
        console.log(res.data);
        setFaultLevel(res.data);
        // setInfeedData({
        //     ...infeedData,
        //     faultLevel: res.data,
        // });
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.", error);
      });
  };

  const getAllFrequency = () => {
    api
      .get("/getAllFrequency")
      .then((res) => {
        console.log(res.data);
        setFrequency(res.data);
        // setInfeedData({
        //     ...infeedData,
        //     frequency: res.data,
        // });
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.", error);
      });
  };

  return (
    <>
      <div className="jc-flex-end d-flex">
        <h4 className="mandatoryText">**All Fields are Mandatory</h4>
      </div>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className="mt-1"
                        fullWidth
                        // disabled={disableAllFields}
                        name="salesEngineer"
                        type="text"
                        label="Sales Engineer"
                        variant="filled"
                        value={infeedData.salesEngineer}
                        onChange={handleTextFieldChange}
                        id="salesEngineer"
                    />
                </Grid> */}
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            className="mt-1"
            fullWidth
            disabled={disableSysInfeedFields}
            name="projectName"
            type="text"
            label="Project Name"
            variant="filled"
            value={infeedData.projectName}
            onChange={handleTextFieldChange}
            id="projectName"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            className="mt-1"
            fullWidth
            disabled={disableSysInfeedFields}
            name="customerName"
            type="text"
            label="Customer Name"
            variant="filled"
            value={infeedData.customerName}
            onChange={handleTextFieldChange}
            id="customerName"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            className="mt-1"
            fullWidth
            disabled={disableSysInfeedFields}
            name="endCustomer"
            type="text"
            label="End Customer"
            variant="filled"
            value={infeedData.endCustomer}
            onChange={handleTextFieldChange}
            id="endCustomer"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.formControl}>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="voltage-label">Voltage (V)</InputLabel>
            <Select
              name="voltage"
              disabled={disableSysInfeedFields}
              label="demo-simple-select-helper-label"
              id="voltage"
              value={
                infeedData.voltage != undefined
                  ? draftActiveData.isDraftEnquiry
                    ? infeedData.voltage
                    : infeedData.voltage.selectedItemID
                  : ""
              }
              onChange={(e, key) => handleFieldChange(e, key)}
              className={classes.fullWidthSelect}
            >
              <MenuItem value="" disabled>
                Voltage (V)
              </MenuItem>
              {voltage.map((item, index) => (
                <MenuItem key={index} value={item.id} disabled={item.id > 5}>
                  {item.voltage}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="frequency-label">Frequency (Hz)</InputLabel>
            <Select
              name="frequency"
              disabled={disableSysInfeedFields}
              label="demo-simple-select-helper-label"
              id="frequency"
              value={
                infeedData.frequency != undefined
                  ? draftActiveData.isDraftEnquiry
                    ? infeedData.frequency
                    : infeedData.frequency.selectedItemID
                  : ""
              }
              onChange={(e, key) => handleFieldChange(e, key)}
              className={classes.fullWidthSelect}
            >
              <MenuItem value="" disabled>
                Frequency (Hz)
              </MenuItem>
              {frequency.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.id}
                  disabled={item.frequency == "60"}
                >
                  {item.frequency}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="faultLevel-label">Fault Level (kA)</InputLabel>
            <Select
              name="faultLevel"
              disabled={disableSysInfeedFields}
              label="demo-simple-select-helper-label"
              id="faultLevel"
              value={
                infeedData.faultLevel != undefined
                  ? draftActiveData.isDraftEnquiry
                    ? infeedData.faultLevel
                    : infeedData.faultLevel.selectedItemID
                  : ""
              }
              onChange={(e, key) => handleFieldChange(e, key)}
              className={classes.fullWidthSelect}
            >
              <MenuItem value="" disabled>
                Fault Level (kA)
              </MenuItem>
              {faultLevel.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.faultLevel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
