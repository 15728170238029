import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { LoginRoute } from "./Routing/LoginRoute";
import { MasterRoute } from "./Routing/MasterRoute";
import {
    authenticateUserBasedOnValidToken,
    validateTheUserBasedOnApi,
} from "./APIs/api_Login";
import {
    saveAccessTokenAction,
    storeUserDetailsAction,
    saveAzureTokenAction,
} from "./Redux/Actions/actions";
import Header from "./Pages/Components/Header/Header";
import "./App.css";
import Loading from "./Pages/Components/Loading/Loading";
import Unauthorized from "./Pages/Unauthorized/Unauthorized";
import IdleTimer from "react-idle-timer";
import { useMsal } from '@azure/msal-react';
//import Loader from "./Components/Loader/Loader";


function App() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    console.log("userDetails", userDetails);
    const [isLoader, setIsLoader] = useState(false);

    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [isAuthorized, setIsAuthorized] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);

    function authenticateUserWithGID(gid) {
        setIsLoader(true);
        authenticateUserBasedOnValidToken(dispatch, activeAccount.idToken, gid)
            .then((response) => {
                if (response) {
                    console.log("app", response)
                    // dispatch(saveAccessTokenAction(activeAccount.idToken));
                    setIsLoader(false);
                    if (response.objResult.roleId > 0) {
                        setIsAuthorized(true);
                    }
                    
                } else {
                    setIsLoader(false);
                    setIsAuthorized(false);
                    setDialogOpen(true);
                }
            })
            .catch((error) => {
                //setDialogOpen(true);
                setIsLoader(false);
            });
    }

    //useEffect(() => {
    //  if (auth.user) {
    //    setIsLoader(true);
    //    if (token) {
    //      const arr = auth.user.profile.sub.split("|");
    //      let gid = arr && arr.length ? arr[1] : "";
    //      authenticateUserBasedOnValidToken(dispatch, token, gid);
    //      // console.log('userDetails', userDetails);
    //      setisAuthorized(true);
    //      setIsLoader(false);
    //    } else {
    //      validateTheUserBasedOnApi(dispatch, auth)
    //        .then((response) => {
    //          setIsLoader(false);
    //        })
    //        .catch((error) => {
    //          setIsLoader(false);
    //          setisAuthorized(false);
    //        });

    //      //validateTheUserBasedOnApi(dispatch, auth);
    //    }
    //  }
    //}, [auth, token, dispatch]);

    useEffect(() => {
        console.log(activeAccount);
        if (activeAccount) {
            setIsLoader(true);
            dispatch(saveAccessTokenAction(activeAccount.idToken));
            dispatch(saveAzureTokenAction(activeAccount.idToken));
            authenticateUserWithGID();
        }
        else {

            setIsLoader(false);

        }


    }, [accounts]);

    function logoutClick() {
       // dispatch(saveAccessTokenAction(null));
        //auth.signoutRedirect();
        // window.location = "https://myid.siemens.com/idp/startSLO.ping";
        localStorage.clear();
        sessionStorage.clear();
        instance.logoutRedirect().catch((error) => console.log(error));
    }

    function sessionExpire(auth, dispatch) {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear();
        sessionStorage.clear();
        if (auth !== undefined) {
            dispatch(storeUserDetailsAction(""));

            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
        window.location.href = "/";
    }

    //const routes = (
    //  <Switch>
    //    <Route exact path={`/`} render={() => <HomePage />} />
    //    <Route exact path={`/InquirySystem`} render={() => <InquirySystem />} />
    //    <Route exact path={`/UserManagement`} render={() => <UserManagement />} />
    //  </Switch>
    //);

    return (
        <>
            <BrowserRouter>

                <div>
                    {userDetails.roleId !== 0 && userDetails.roleId !== undefined && userDetails ? (
                        <div className="amps-master-block">
                            <Header>Header Component</Header>
                            <main className="main-component">{MasterRoute}</main>
                        </div>
                    ) : isAuthorized === false ? (
                        <Unauthorized />
                    ) :
                        LoginRoute
      }
                </div>
                {/*<div>*/}
                {/*  {(userDetails &&*/}
                {/*    userDetails.gid === "" &&*/}
                {/*    userDetails.roleId !== 0)  ? (*/}
                {/*    <div className="amps-master-block">*/}
                {/*      <Header logoutClick={logoutClick.bind()}>Header Component</Header>*/}
                {/*      <main className="main-component">{MasterRoute}</main>*/}
                {/*    </div>*/}
                {/*  ) :(*/}
                {/*    LoginRoute*/}
                {/*  )}*/}
                {/*</div>*/}
                {/*<div className="App">*/}
                {/*    {userDetails && userDetails.roleId > 0 ? (*/}
                {/*        <div className="">*/}
                {/*            <Header>Header Component</Header>*/}
                {/*            {userDetails.gid === ""*/}
                {/*                ? <div className="amps-master-block">*/}
                {/*                         <Header logoutClick={logoutClick.bind()}>Header Component</Header>*/}
                {/*                         <main className="main-component">{MasterRoute}</main>*/}
                {/*                   </div>*/}
                                
                {/*                        : LoginRoute}*/}
                {/*        </div>*/}
                {/*    ) : <></>}*/}
                   
                {/*</div>*/}
               
                <div>
                    <IdleTimer
                        timeout={1000 * 60 * 20}
                        onIdle={sessionExpire}
                        debounce={250}
                    />
                    {isLoader === true ? <Loading /> : <></>}
                </div>

            </BrowserRouter>
        </>
    );
}

export default App;
