import React from "react";
import { useState, useEffect } from "react";
import SiemensLogo from "../../Assets/images/sie-logo-white-rgb.png";
import {
  Container,
  Box,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {
  validateTheUserBasedOnApi,
  authenticateExternalUser,
  authenticateContractUser,
} from "../../APIs/api_Login";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import "./login.scss";
import UserManagement from "../../Pages/UserManagement/UserManagement";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "rgba(0, 204, 204, .05)",
    // backdropFilter: "blur(3px)",
    minHeight: "100vh",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
  },
  errorMessage: {
    color: "var(--red)",
    fontSize: "16px",
  },
  timerDetails: {
    backgroundColor: "var(--bg-green-2)",
  },
}));

export default function LoginPage() {
  const [user, setUser] = useState([]);
  const [userRoleID, setUserRoleID] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userId, setUserId] = useState("");
  const dispatch = useDispatch();
  const auth = useAuth();
    const { instance } = useMsal();
  const classes = useStyles();

  const redirectToMyId = () => {
    getUserDetails();
  };

  function myIDLogin() {
    const Removedata = localStorage.removeItem("LSSystemInfeedId");
    dispatch(auth.signinRedirect);
    }

    function azureADLogin() {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
        // dispatch(auth.signinRedirect);
    }

  const getUserDetails = async () => {
    // const response
    await fetch("Login/GetUser", {
      mode: "no-cors",
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          // console.log(json);
          const data = json;
          setUser(JSON.stringify(data));
          setUserRoleID(data["roleId"]);
          setUserFirstName(data["firstName"]);
          setUserLastName(data["lastName"]);
          setUserId(data["id"]);

          //
          //<Route path='/user-management' component={UserManagement} />
        });
      }
      //else
      // window.location.reload();
    });
  };

  return (
    <div className="bg-login-register">
      <div className={classes.root}>
        <Container>
          <Grid container className="pt-lr-mobileContainer">
            <Grid item xs={12}>
              <div className="pt-login-logo-block">
                <img
                  src={SiemensLogo}
                  className="official-logo"
                  alt="Siemens"
                />
              </div>
            </Grid>
            <Grid container className="login-container">
              <Grid
                item
                xs={12}
                sm={10}
                md={5}
                className="pt-lr--project-block"
              >
                <div className="pt-lr--project">
                  <p className="pt-lr-project-name">
                    AMPS v4.0
                    <div className="bannerInnerTextFull">
                      Assured Motor Protection Software
                    </div>
                  </p>
                  <span className="pt-lr--project-description">
                    Simple, Flexible, Reliable
                  </span>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={10} md={5} className="pt-lr-master-block">
                                <div className="pt-lr-block">
                                    <div className="pt-login-siemens_user">
                                        <h3>Siemens Employee Login </h3>
                                        <span>If you are a Siemens Employee, login via MyID</span>
                                        <div className="d-flex jc-center myid-login-btn-block">
                                            <Link to="/">
                                                <Button
                                                    className="pt-button--hero myid-login-btn"
                                                    id="myIDLoginBtn"
                                                    onClick={myIDLogin}
                                                >
                                                    <span>MyID Login</span>
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Grid> */}
            </Grid>
            <Grid container className="login-container">
              <Grid item xs={12} sm={10} md={5} className="pt-lr-master-block">
                <div className="pt-lr-block myid-login-text">
                  <div className="pt-login-siemens_user">
                    <h3>Siemens Employee Login </h3>
                                      <span>If you are a Siemens Employee, login via Azure AD</span>
                                      <div className="d-flex jc-center mt-1">
                                          <Link to="/">
                                              <Button
                                                  className="pt-button--hero myid-login-btn-block"
                                                  id="AzureAdLogin"
                                                  onClick={azureADLogin}
                                              >
                                                  <span>Azure AD Login</span>
                                              </Button>
                                          </Link>
                                      </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
