import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";

export default function SnackbarCustom(props) {
  const [state, setState] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={3000}
      onClose={props.handleClose}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
      role="alert"
      className={props.classes}
    >
      <Alert
        icon={false}
        severity={props.alertType}
        variant="filled"
        action={
          <CloseIcon onClick={props.handleClose} style={{ color: "#FFFFFF" }} />
        }
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}
