import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import { tableIcons } from "../../Utilities/utility";
import "./Configuration.scss";

export function StarDeltaOutput() {
  let SDTableData = [];
  SDTableData = useSelector((state) => state.starDeltaTableDataReducer);
  const motorRating = useSelector((state) => state.motorRatingInputReducer);
  const efficiencyType = useSelector(
    (state) => state.efficiencyTypeInputReducer
  );
  function renderSelectionType(adminEnquiryType) {
    const selType = adminEnquiryType === 1 ? "Tested" : "Recommended";
    return selType;
  }
  function renderMotorRating(value) {
    const motRating = motorRating.find((x) => x.id === value)?.motorRating;
    return motRating;
  }
  function renderEfficiencyType(effValue) {
    const effVal = parseInt(effValue);
    const effType = efficiencyType.find((x) => x.id === effVal)?.efficiencyType;
    return effType;
  }

  useEffect(() => {
    console.log("SDTableData", SDTableData);
  });
  var columns = [
    //checking for output screen
    // { title: "Eff", field: 'efficiencyClass', width: '10%' },
    {
      title: "Eff. Class",
      field: "class",
      width: "10%",
      render: (rowData) => renderEfficiencyType(rowData.class),
    },

    // { title: 'Motor Rating(kW)', field: 'rating' },
    {
      title: "Motor Rating (kW)",
      field: "motorRating",
      width: "5%",
      render: (rowData) => renderMotorRating(rowData.motorRating),
    },
    { title: "Motor FLC (A)", field: "motorFlc" },
    { title: "SCPD Type", field: "mlfbMasterData" },
    { title: "SCPD Rating (A)", field: "mlfbMasterRatingData" },
    { title: "Contactor (L & D) Type", field: "siriusChartContactorData" },
    {
      title: "Contactor (L & D) Rating (A)",
      field: "siriusChartContactorRatingData",
    },
    { title: "Contactor (Star) Type", field: "starMlfb5" },
    { title: "Contactor (Star) Rating (A)", field: "starContactorRating4" },
    {
      title: "OLPD Type",
      field: "olrmlfb",
      render: (rowData) =>
        rowData.olrmlfb == null || rowData.olrmlfb == "undefined"
          ? "Failed"
          : rowData.olrmlfb,
    },
    {
      title: "OLPD Rating (A)",
      field: "olrrating",
      render: (rowData) =>
        rowData.olrrating == null || rowData.olrrating == "undefined"
          ? "Failed"
          : rowData.olrrating,
    },
    {
      title: "Selection",
      field: "adminEnquiryType",
      render: (rowData) => renderSelectionType(rowData.adminEnquiryType),
    },
    // { title: 'Motor Efficiency', field: 'motorEfficiency' },
    // { title: 'FLC (A)', field: 'flc' },
    // { title: 'LLR', field: 'lrr', hidden: true },
    // { title: 'Starting Method', field: 'startingMethod' },
    // { title: 'Coordination', field: 'coOrdination' },
    // { title: 'Types', field: 'types' },
    // { title: 'SCPD', field: 'scpd' },
    // { title: 'Contactor', field: 'contactor' },
    // { title: 'OverloadRelay', field: 'overLoadRelay' },
    // { title: 'Cooling Fan Motor Current', field: 'coolingFanMotorCurrent', hidden: true },
    // { title: 'Power Factor', field: 'powerFactor', hidden: true },
    // { title: 'Service Factor', field: 'serviceFactor', hidden: true }
  ];
  const tableHeaderStyle = {
    backgroundColor: "var(--light-sand)",
    color: "var(--dark-blue)",
  };
  const tableOptions = {
    headerStyle: tableHeaderStyle,
    showTitle: false,
    draggable: false,
    // exportButton: {
    //     csv: true,
    //     pdf: false
    // },
    // exportAllData: true,
    pageSize: 3,
    pageSizeOptions: [3, 10, 20, 30],
    // emptyRowsWhenPaging: false
    sorting: false,
  };
  return (
    <>
      {SDTableData.starDeltaTableData != "" ? (
        <div className="matTableClass">
          <MaterialTable
            title=""
            columns={columns}
            data={SDTableData.starDeltaTableData}
            icons={tableIcons}
            options={tableOptions}
          />
        </div>
      ) : (
        <h4>No Star Delta Enquiry Found</h4>
      )}
    </>
  );
}
