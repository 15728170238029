import * as myConstClass from "../Formula/Constants";

/***** Parameter calculation for switchgear selection B.1 *****/
//calculate FLA
export function getFLALine(motorFLC, motorServiceFactor, fanMotorFLC) {
  //    return ((motorFLC * motorServiceFactor) + fanMotorFLC);
  return parseFloat(motorFLC * motorServiceFactor) + parseFloat(fanMotorFLC);
}

//calculate Motor Starting Time
export function getMotorStartingTime(motorStartingTimeVal) {
  return myConstClass.motorStartingTimeFactor * motorStartingTimeVal;
}

//calculate LRA (for contactor selection)
export function getLraContactor(motorFLC, motorLrr) {
  return motorFLC * motorLrr * myConstClass.lraforContactorSelectionfactor;
}

//calculate LRA (For OL char. selection)
export function getLraOverload(motorFLC, motorLrr) {
  return motorFLC * motorLrr;
}
/***** End of Parameter calculation for switchgear selection B.1 *****/

/***** SCPD selection B.2 *****/
//calculate SCPD - DOL min range
export function getScpdDOLMinRange(flaLine, motorRatingSelected) {
  if (motorRatingSelected <= 25)
    return flaLine - myConstClass.scpdSafetyFactor * flaLine;
  else return flaLine - myConstClass.newScpdSafetyFactor * flaLine;
}

//calculate SCPD - DOL max range
export function getScpdDOLMaxRange(flaLine, motorRatingSelected) {
  if (motorRatingSelected <= 25) {
    return (
      parseFloat(flaLine) + parseFloat(myConstClass.scpdSafetyFactor * flaLine)
    );
  } else {
    return flaLine + myConstClass.newScpdSafetyFactor * flaLine;
  }
}

//calculate Lower bound of OL tripping zone time on LRA
export function getOlTrippingZTLRALB(olTrippingZTLRA) {
  return (
    olTrippingZTLRA -
    myConstClass.scpdOlTrippingZoneTimeLowerBandFactor * olTrippingZTLRA
  );
}

//calculate upper bound of OL tripping zone time on LRA
export function getOlTrippingZTLRAUB(olTrippingZTLRA) {
  return (
    parseFloat(olTrippingZTLRA) +
    parseFloat(
      myConstClass.scpdOlTrippingZoneTimeUpperBandFactor * olTrippingZTLRA
    )
  );
}

//calculate SC zone based on LRA
export function getScSettingLRA(scpdRatingMaxRangeMaster, scSetting) {
  return scpdRatingMaxRangeMaster * scSetting;
}

//calculate Lower bound of SC zone based on LRA
export function getScSettingLRALB(scSettingLRA) {
  return scSettingLRA - myConstClass.scZoneLRALowerBandFactor * scSettingLRA;
}

//calculate upper bound of SC zone based on LRA
export function getScSettingLRAUB(scSettingLRA) {
  return (
    parseFloat(scSettingLRA) +
    parseFloat(myConstClass.scZoneLRAUpperBandFactor * scSettingLRA)
  );
}

//Let through of SCPD required S/C (sr. no. 6)
export function getLetThroughOfSCPD(scpdKaMaster) {
  return scpdKaMaster / 1000;
}
/***** End of SCPD selection B.2 *****/

/***** Contactor selection B.3  *****/
//Let through of contactor safety factor
export function geLetThroughOfContactorSafetyFac(letThroughOfContactor) {
  return letThroughOfContactor / myConstClass.letThroughOfContactorSafetyFactor;
}
/***** End of Contactor selection B.3  *****/

/****** ----- Overload Relay selection ----- ******/
//Condition for OLR Min range
export function getOLRMinRange(flaLine, motorRatingSelected) {
  if (motorRatingSelected <= 25) {
    return flaLine - myConstClass.olrSafetyFactor * flaLine;
  } else return flaLine - myConstClass.olrSafetyFactorNew * flaLine;
}

//Condition for OLR Max range
export function getOLRMaxRange(flaLine, motorRatingSelected) {
  if (motorRatingSelected <= 25) {
    return (
      parseFloat(flaLine) + parseFloat(myConstClass.olrSafetyFactor * flaLine)
    );
  } else {
    return (
      parseFloat(flaLine) +
      parseFloat(myConstClass.olrSafetyFactorNew * flaLine)
    );
  }
}

//calculate Lower bound of OL tripping zone time on LRA for OLR
export function getOlTrippingZTLRALBOLR(olTrippingZTLRAOLR) {
  return (
    olTrippingZTLRAOLR -
    myConstClass.olrTrippingZoneTimeLowerBandFactor * olTrippingZTLRAOLR
  );
}

//calculate upper bound of OL tripping zone time on LRA for OLR
export function getOlTrippingZTLRAUBOLR(olTrippingZTLRAOLR) {
  return (
    parseFloat(olTrippingZTLRAOLR) +
    parseFloat(
      myConstClass.olrTrippingZoneTimeUpperBandFactor * olTrippingZTLRAOLR
    )
  );
}

/***** End of Overload Relay selection  -----******/

/****** -----Star Delta Claculation ----- ******/
//calculate FLA Phase
export function getSDFLAPhase(motorFLC, motorServiceFactor) {
  return (
    parseFloat(motorFLC * motorServiceFactor) *
    parseFloat(myConstClass.SDFlaphasefactor)
  );
}
export function getSDLRADOL(motorFLC, motorLrr) {
  return motorFLC * motorLrr * myConstClass.SDLraDolfactor;
}
//calculate LRA - SD(For contactor selection)
export function getlracontactor(SDlradol) {
  return SDlradol / myConstClass.SDLraSdfactor;
}
export function getOlChar(motorFLC, motorLrr) {
  return (motorFLC * motorLrr) / myConstClass.lraOLselectionfactor;
}
export function getSDStarcurrent(motorFLC, serviceFactor) {
  return (motorFLC * serviceFactor) / myConstClass.SDstarcurrentfactor;
}
export function getSCPDSDvalue(SDflaLine, motorRatingSelected) {
  if (motorRatingSelected <= 25) {
    return parseFloat(SDflaLine) + myConstClass.scpdSafetyFactor * SDflaLine;
  } else
    return parseFloat(SDflaLine) + myConstClass.newScpdSafetyFactor * SDflaLine;
}
//calculate SD SC zone based on LRA
export function getSDScSettingLRA(scpdSDRatingMaxRangeMaster, scSDSetting) {
  return scpdSDRatingMaxRangeMaster * scSDSetting;
}
//calculate Lower bound of SD SC zone based on LRA
export function getSDScSettingLRALB(scSDSettingLRA) {
  return (
    scSDSettingLRA - myConstClass.scSDZoneLRALowerBandFactor * scSDSettingLRA
  );
}

//calculate upper bound of SD SC zone based on LRA
export function getSDScSettingLRAUB(scSDSettingLRA) {
  return (
    parseFloat(scSDSettingLRA) +
    parseFloat(myConstClass.scSDZoneLRAUpperBandFactor * scSDSettingLRA)
  );
}
//Let through of SD contactor safety factor
export function geLetThroughOfSDContactorSafetyFac(letThroughOfSDContactor) {
  return (
    letThroughOfSDContactor / myConstClass.letThroughOfSDContactorSafetyFactor
  );
}
//Condition for OLR Min range
export function getSDOLRMinRange(SDflaphase, motorRatingSelected) {
  if (motorRatingSelected <= 25)
    return SDflaphase - myConstClass.SDolrSafetyFactor * SDflaphase;
  else return SDflaphase - myConstClass.newSDolrSafetyFactor * SDflaphase;
}

//Condition for OLR Max range
export function getSDOLRMaxRange(SDflaphase, motorRatingSelected) {
  if (motorRatingSelected <= 25) {
    return (
      parseFloat(SDflaphase) +
      parseFloat(myConstClass.SDolrSafetyFactor * SDflaphase)
    );
  } else {
    return (
      parseFloat(SDflaphase) +
      parseFloat(myConstClass.newSDolrSafetyFactor * SDflaphase)
    );
  }
}
//calculate Lower bound of OL tripping zone time on LRA for OLR
export function getSDOlTrippingZTLRALBOLR(SDolTrippingZTLRAOLR) {
  return (
    SDolTrippingZTLRAOLR -
    myConstClass.SDolrTrippingZoneTimeLowerBandFactor * SDolTrippingZTLRAOLR
  );
}

//calculate upper bound of OL tripping zone time on LRA for OLR
export function getSDOlTrippingZTLRAUBOLR(SDolTrippingZTLRAOLR) {
  return (
    parseFloat(SDolTrippingZTLRAOLR) +
    parseFloat(
      myConstClass.SDolrTrippingZoneTimeUpperBandFactor * SDolTrippingZTLRAOLR
    )
  );
}
export function getlracontactortolerance(lraContactor, coordinationSelected) {
  //   return (
  //     parseFloat(lraContactor) +
  //     parseFloat(lraContactor * myConstClass.LRAContactorToleranceFac)
  //   );
  // new changes
  if (coordinationSelected == 2) {
    return (
      parseFloat(lraContactor) +
      parseFloat(lraContactor * myConstClass.newLRAContactorToleranceFacType2)
    );
  } else {
    return (
      parseFloat(lraContactor) +
      parseFloat(lraContactor * myConstClass.newLRAContactorToleranceFacType1)
    );
  }
}
/***** End of Star Delta Claculation  -----******/
