import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import './UserManagement.scss';
import { showAdminInfoAction } from '../../Redux/Actions/actions';

export default function AdminUser() {
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(showAdminInfoAction(true));
    },[]);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3} >
                    <Link to="/UserManagement">
                        <div className="adminMenu-item-container">
                            <div className="adminMenu-item-text-block">
                                <span>User Management</span>
                            </div>
                        </div>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={3} >
                    <Link to="/InquirySystem">
                        <div className="adminMenu-item-container">
                            <div className="adminMenu-item-text-block" onClick={() => {
                                dispatch(showAdminInfoAction(true))
                            }}>
                                <span>New Configuration</span>
                            </div>
                        </div>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={3} >
                    <Link to="/ExistingConfiguration">
                        <div className="adminMenu-item-container">
                            <div className="adminMenu-item-text-block" onClick={() => {
                                dispatch(showAdminInfoAction(true))
                            }}>
                                <span>Draft Enquiries</span>
                            </div>
                        </div>
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
}