import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import * as myConstClass from "../Formula/Constants";
import * as myFunctions from "../Formula/Formula";

export default function SystemInfeedInputDetails() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    //variable declaration
    let voltage = 5; //id
    let voltageVal = 440;
    let Frequency = 1;
    let faultLevel=50;
    let role = userDetails.roleId;;

    //this values will be taken from ddl
    var [motorRating, setMotorRating] = useState(13);  //3 rating
    var [efficiencyType, setEfficiencyType] = useState(1); //IE2
    var [startingMethod, setStartingMethod] = useState(1);  //DOL id 1, RDOl, SD
    var [fuseType, setFuseType] = useState(2);  //for Fuseless
    var [typeOfCordination, setTypeOfCordination] = useState(2);  //for TypeOfCordination
    var [mlfb, setMlfb] = useState(1);  //for 3RV
    var [overloadRelay, setOverloadRelay] = useState(1);  //for 3RU
    var [contactor, setContactor] = useState(1);  

    var [motorFLC, setmMtorFLC] = useState("");
    var [motorEfficiency, setMotorEfficiency] = useState("");
    var [motorLRR, setMotorLRR] = useState("");
    var [powerFactor, setPowerFactor] = useState("");

    //output variables
    

    const api = axios.create({
        baseURL: 'SystemInfeed'
    })

    useEffect(() => {
        getMotorDetails();
        getMotorRating();
        getEfficiencyType();
        getStartingMethod();
        getTypeOfCoordination();
        getFuseType();
        getScpdFuseProduct();
        getScpdFuselessProduct();
        getContactor();
        getOverloadRelayProduct();
    }, []);

    const getMotorRating = () => {
        api.get('/getMotorRating')
            .then(res => {
                console.log('MotorRating', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getEfficiencyType = () => {
        api.get('/getEfficiencyType')
            .then(res => {
                console.log('EfficiencyType',res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getStartingMethod = () => {
        api.get('/getStartingMethod')
            .then(res => {
                console.log('StartingMethod', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getTypeOfCoordination = () => {
        api.get('/getTypeOfCoordination')
            .then(res => {
                console.log('TypeOfCoordination', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getFuseType = () => {
        api.get('/getFuseType')
            .then(res => {
                console.log('FuseType', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getScpdFuseProduct = () => {
        api.get('/getScpdFuseProduct')
            .then(res => {
                console.log('SCPDFuseProduct', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getScpdFuselessProduct = () => {
        api.get('/getScpdFuselessProduct')
            .then(res => {
                console.log('ScpdFuselessProduct', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getContactor = () => {
        api.get('/getContactor')
            .then(res => {
                console.log('Contactor', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getOverloadRelayProduct = () => {
        api.get('/getOverloadRelayProduct')
            .then(res => {
                console.log('OverloadRelayProduct', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }

    const getMotorDetails = () => {
        let paramMotors = {};
        paramMotors = {
            "MotorRating": motorRating, //id of motorRating ddl selected value
            "Efficiency": efficiencyType, //id of efficiency ddl selected value
            "Voltage": voltage //id of voltage ddl selected value
        }
        api.post('/getMotorDetails', paramMotors)
            .then(function (response) {
                console.log('Motor Details', response.data);
                setMotorEfficiency(response.data.motorEfficiency);
                setMotorLRR(response.data.motorLrr);
                setmMtorFLC(response.data.motorFlc);
                setPowerFactor(response.data.powerFactor);

                if (startingMethod == 1 || startingMethod == 2)
                    calculateDOL(response.data.motorFlc, response.data.motorLrr);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    /* ------ background calculation -------*/

    var motorServiceFactor = 1; // get from textbox
    var fanMotorFLC = 0;  // get from textbox
    var flaLine;
    var motorStartingTimeVal = 6; // get from textbox
    var motorStartingTime;
    var lraContactor, lraOverload;

    var scpdDOLMinRange, scpdDOLMaxRange;
    var scpdRatingMaster, scpdRatingMinRangeMaster, scpdRatingMaxRangeMaster;
    var selectedMlfbMaster, selectedMlfbMagOnlyMaster, scpdKaMaster, scpdSizeMaster;

    var olTrippingZTLRA, olTrippingZTLRAMLFB = 0;
    var olTrippingZTLRALB, olTrippingZTLRAUB;

    var scSetting, scSettingLRA, scSettingLRALB, scSettingLRAUB;
    var letThroughOfSCPD, letThroughOfSCPDVal=0;

    var isSCPDhighercurrent = false;
    var isOLTZTLRAhigher = false;
    var isSCLowerBand = false;
    var isMLFBCorrect = false;

    const calculateDOL = async (motorFLC, motorLrr) => {

        /***** Parameter calculation for switchgear selection B.1 *****/
        //calculate FLA
        flaLine = myFunctions.getFLALine(motorFLC, motorServiceFactor, fanMotorFLC);
      
        //calculate StartingTime 
        motorStartingTime = myFunctions.getMotorStartingTime(motorStartingTimeVal);

        //calculate LRA (for contactor selection)    
        lraContactor = myFunctions.getLraContactor(motorFLC,motorLrr);
        lraOverload = myFunctions.getLraOverload(motorFLC,motorLrr);

        /***** SCPD selection B.2 *****/
        //calculate SCPD - DOL min max range
        scpdDOLMinRange = myFunctions.getScpdDOLMinRange(flaLine);
        scpdDOLMaxRange = myFunctions.getScpdDOLMaxRange(flaLine);

        //scpd rating
        var selectedMlfbMasterData = await getselectedMlfbMasterData(scpdDOLMinRange, scpdDOLMaxRange);//get nearest rating data from mstMLFB table
        if (getselectedMlfbMasterData != undefined) {
            if (role != 1) //other than admin
            {
                selectedMlfbMasterData = selectedMlfbMasterData.find(({ category }) => category === 'Tested');
                if (selectedMlfbMasterData != undefined) {
                    scpdRatingMaster = selectedMlfbMasterData.rating;//get rating from mstMLFB table
                    scpdRatingMinRangeMaster = selectedMlfbMasterData.minRange;//get min range based on above row get nearest range from mstMLFB table
                    scpdRatingMaxRangeMaster = selectedMlfbMasterData.maxRange;//get max range based on above row get nearest range from mstMLFB table
                    selectedMlfbMaster = selectedMlfbMasterData.standardRelease;//get std release mlfb from mstMLFB table, for admin it will get 2 and for other user it will get 1 value
                    selectedMlfbMagOnlyMaster = selectedMlfbMasterData.magOnly;//get mlfbMagOnly from mstMLFB table, for admin it will get 2 and for other user it will get 1 value
                    scpdKaMaster = selectedMlfbMasterData.voltKa; //get ka from mstMLFB table
                    scpdSizeMaster = selectedMlfbMasterData.size; //get size from mstMLFB table


                    //OL tripping zone time on LRA
                    var allMlfbProductData = await getMlfbProductData(selectedMlfbMaster, flaLine);//get all 3poleLoadFactor values and its ta from mstMLFBProductDetail
                    if (allMlfbProductData != undefined && allMlfbProductData.length > 0) {
                        var olTrippingZTLRA = allMlfbProductData.find(({ threePoleFactor }) => threePoleFactor >= lraOverload);

                        if (olTrippingZTLRA != undefined)
                            olTrippingZTLRAMLFB = olTrippingZTLRA.threePoleTa;// get its ta

                        //calculate Lower bound and upper bound of OL tripping zone time on LRA
                        olTrippingZTLRALB = myFunctions.getOlTrippingZTLRALB(olTrippingZTLRAMLFB);
                        olTrippingZTLRAUB = myFunctions.getOlTrippingZTLRAUB(olTrippingZTLRAMLFB);

                        //calculate SC zone based on LRA
                        //SC setting  things[0].event
                        scSetting = allMlfbProductData[0].shortCircuitReleaseFactor; //take always highest value
                        scSettingLRA = myFunctions.getScSettingLRA(scpdRatingMaxRangeMaster, scSetting);
                        //calculate Lower bound and upper bound of SC zone based on LRA
                        scSettingLRALB = myFunctions.getScSettingLRALB(scSettingLRA);
                        scSettingLRAUB = myFunctions.getScSettingLRAUB(scSettingLRA);

                        //Let through of SCPD required S/C (sr. no. 6)
                        letThroughOfSCPD = allMlfbProductData.sort(function (a, b) {
                            return Math.abs(a._400vI2tCharactersticIk - scpdKaMaster) - Math.abs(b._400vI2tCharactersticIk - scpdKaMaster)
                        })[0];
                        if (letThroughOfSCPD != undefined)
                            letThroughOfSCPDVal = letThroughOfSCPD._400vI2tCharactersticI2t;
                    }
                }
            }
            else {
                //for admin
                console.log('selectedMlfbMasterData', selectedMlfbMasterData);
            }               
        }       
                

        //Condition to verify
        if (scpdRatingMaxRangeMaster >= scpdDOLMaxRange)
            isSCPDhighercurrent = true;

        if (olTrippingZTLRALB >= motorStartingTime)
            isOLTZTLRAhigher = true;

        if (scSettingLRALB > (myConstClass.scLowerBandFactor * (lraContactor)))
            isSCLowerBand = true;

        if (isSCPDhighercurrent == true && isOLTZTLRAhigher == true && isSCLowerBand==true)
            isMLFBCorrect = true;
        console.log('Mlfb', isMLFBCorrect);

        /***** Contactor selection B.3  *****/
        if (contactor != null || contactor != undefined)
            calculateContactor(flaLine, lraContactor, letThroughOfSCPDVal);

        /****** ----- Overload Relay selection ----- ******/
        if (overloadRelay != null || overloadRelay != undefined)
            calculateOLR(flaLine, motorStartingTime, lraOverload);
    }

    /***** Contactor selection B.3  *****/
    const calculateContactor = async (flaLine, lraContactor, letThroughOfSCPDVal) => {

        var contactorRatingMaster, contactorSiriusRatingMaster, selectedContactorMaster, contactorSizeMaster;
        var thermalRatingMaster;
        var letThroughOfContactor, letThroughOfContactorSafetyFac;
        var isContactorRatedcurrent = false;
        var isContactor10sRating = false;
        var isContactorLetThrough = false;  

        //get data from Sirius chart by flaline
        var siriusChartContactorMasterData = await getSiriusChartContactorMaster(flaLine);
        if (siriusChartContactorMasterData != undefined) {
            contactorSiriusRatingMaster = siriusChartContactorMasterData.mpcbrating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaLine variable)
            contactorRatingMaster = siriusChartContactorMasterData.contactorRating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaLine variable)
            selectedContactorMaster = siriusChartContactorMasterData.mlfb; // get contactor from Sirius chart 
            contactorSizeMaster = siriusChartContactorMasterData.contactorSize; // get size from Sirius chart 

            //get Thermal Rating from ContactorMaster by contactorRatingMaster variable value
            var contactorRatingMasterData = await getContactorRatingMaster(contactorRatingMaster);
            if (contactorRatingMasterData != undefined) {
                thermalRatingMaster = contactorRatingMasterData.thermalLoading10s; //get 10S (as per req voltage) column value from contactorMaster 
                letThroughOfContactor = (thermalRatingMaster * thermalRatingMaster * myConstClass.letThroughOfContactorThermalRatingFactor) / myConstClass.letThroughOfContactorFactor;
                letThroughOfContactorSafetyFac = myFunctions.geLetThroughOfContactorSafetyFac(letThroughOfContactor);
            }
        }
        
        
        //Condition to verify
        if (contactorRatingMaster > flaLine)
            isContactorRatedcurrent = true;

        if (thermalRatingMaster > lraContactor)
            isContactor10sRating = true;

        if (letThroughOfContactorSafetyFac > letThroughOfSCPDVal)
            isContactorLetThrough = true;
        console.log('Contactor', isContactorLetThrough)
    }

    /****** ----- Overload Relay selection ----- ******/
    const calculateOLR = async (flaLine, motorStartingTime, lraOverload)  =>  {
        var OLRMinRange, OLRMaxRange;
        var OLRRatingMaster, OLRMinRangeMaster, OLRMaxRangeMaster, selectedOLRMlfbMaster;
        var olTrippingZTLRAOLR=0, olTrippingZTLRALBOLR, olTrippingZTLRAUBOLR;

        var isOLRhighercurrent = false;
        var isOLRTZTLRAhigher = false;
        var isOLRCorrect = false;

        //Condition for OLR
        OLRMinRange = myFunctions.getOLRMinRange(flaLine);
        OLRMaxRange = myFunctions.getOLRMaxRange(flaLine);   

        //get relay size from Sirius chart
        var siriusChartOLRSize = await getSiriusChartOLRMaster(OLRMinRange, OLRMaxRange);

        //get above sirius chart size data of selected range from overload relay master
        var selectedOLRMlfbMasterData = await getSelectedOLRMlfbMaster(OLRMinRange, OLRMaxRange, siriusChartOLRSize);

        
        selectedOLRMlfbMasterData = selectedOLRMlfbMasterData.find(({ size }) => size === 'S0');

        OLRMinRangeMaster = selectedOLRMlfbMasterData.minRange;//get min range based on above row get nearest range from 3RURelay table
        OLRMaxRangeMaster = selectedOLRMlfbMasterData.maxRange;//get max range based on above row get nearest range from 3RURelay table
        selectedOLRMlfbMaster = selectedOLRMlfbMasterData.mlfb;//get mlfb from 3RURelay table
        OLRRatingMaster = selectedOLRMlfbMasterData.rating;//get rating from 3RURelay master table

        //OL tripping zone time on LRA
        var get3PoleLFOLRData = await getOverloadRelayProductDetail(selectedOLRMlfbMaster, flaLine); //get all (3poleLoadFactor *flaline) values and its ta from mstRURelay master table

        var newPoleLFOLR = get3PoleLFOLRData.find(({ threePoleFactor }) => threePoleFactor >= lraOverload);

        if (newPoleLFOLR != undefined)
            olTrippingZTLRAOLR = newPoleLFOLR.threePoleTa;// get values greater than lraOverload and get its ta

        //calculate Lower bound and upper bound of OL tripping zone time on LRA 
        olTrippingZTLRALBOLR = myFunctions.getOlTrippingZTLRALBOLR(olTrippingZTLRAOLR);
        olTrippingZTLRAUBOLR = myFunctions.getOlTrippingZTLRAUBOLR(olTrippingZTLRAOLR);
       
            

        //Condition to verify
        if (OLRMaxRangeMaster >= OLRMaxRange)
            isOLRhighercurrent = true;

        if (olTrippingZTLRALBOLR >= motorStartingTime)
            isOLRTZTLRAhigher = true;

        if (isOLRhighercurrent == true && isOLRTZTLRAhigher == true)
            isOLRCorrect = true;
        console.log('OverloadRelay', isOLRCorrect);
    }
    /**** ------- ****/
    
    /**** ------- ****/
    //get MLFB Master data
    const getselectedMlfbMasterData = async (scpdDOLMinRange, scpdDOLMaxRange) => {
        let paramMlfb = {};
        paramMlfb = {
            "MinRange": scpdDOLMinRange,
            "MaxRange": scpdDOLMaxRange,
            "ProductGroup": mlfb,
            "FuseType": fuseType,
            "Voltage": voltageVal
        }
        const res = await api.post('/getselectedMlfbMasterData', paramMlfb)
        return await res.data;
    }
    //get MLFB Product Master data
    const getMlfbProductData = async (selectedMlfbMaster, flaLine) => {
        let paramMlfb = {};
        paramMlfb = {
            "ProductName": selectedMlfbMaster,
            "FlaLine": flaLine
        }
        const res = await api.post('/getMlfbProductData', paramMlfb)
        return await res.data;
    }


    //get Overload Relay Master data
    const getSelectedOLRMlfbMaster = async (OLRMinRange, OLRMaxRange, siriusChartOLRSize) => {
        let paramOLRMlfb = {};
        paramOLRMlfb = {            
            "MinRange": OLRMinRange, 
            "MaxRange": OLRMaxRange, 
            "ProductGroup": overloadRelay,
            "Size": siriusChartOLRSize
        } 
        const res = await api.post('/getSelectedOLRMlfbMaster', paramOLRMlfb)
        return await res.data;
    }
    //get Overload Relay Product Master data
    const getOverloadRelayProductDetail = async (selectedOLRMlfbMaster, flaLine) => {
        let param3PoleLFOLR = {};
        param3PoleLFOLR = {
            "ProductName": selectedOLRMlfbMaster,
            "FlaLine": flaLine
        }
        const res = await api.post('/getOverloadRelayProductDetail', param3PoleLFOLR)        
        return await res.data;
    }
    const getSiriusChartOLRMaster = async (OLRMinRange, OLRMaxRange) => {
        let paramContactor = {};
        paramContactor = {
            "MinRange": OLRMinRange,
            "MaxRange": OLRMaxRange,         
            "Voltage": voltageVal,
            "TypeOfCordination": typeOfCordination
        }
        const res = await api.post('/getSiriusChartOLRMaster', paramContactor)
        return await res.data;
    }
    


    //get Contactor Master data    
    const getSiriusChartContactorMaster = async (flaLine) => {
        let paramContactor = {};
        paramContactor = {           
            "FlaLine": flaLine,
            "Voltage": voltageVal,
            "TypeOfCordination": typeOfCordination
        }
        const res = await api.post('/getSiriusChartContactorMaster', paramContactor)
        return await res.data;
    }
    const getContactorRatingMaster = async (contactorRatingMaster) => {
        let paramContactor = {};
        paramContactor = {
            "ProductId": contactor,
            "Voltage": voltage,
            "Rating": contactorRatingMaster
        }
        const res = await api.post('/getContactorRatingMaster', paramContactor)
        return await res.data;
    }


    /******** ----------- *********/


    return (
        <div>
            motorFLC : {motorFLC} <br />
            motorEfficiency : {motorEfficiency} <br />
            motorLRR : {motorLRR} <br />
            powerFactor : {powerFactor} <br />
        </div>
    );
}