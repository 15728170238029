import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DOLOutput } from './DOLOutput';
import { RDOLOutput } from './RDOLOutput';
import { StarDeltaOutput } from './StarDeltaOutput';
import './Output.scss'

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordioncolor:
    {
        background: '#D1FFF2'
    }
}));

export function Output() {
    const classes = useStyles();

    return (
        <>
            <h3>Output</h3>
            <div className='outputTableContainer'>
                <Accordion >
                    <AccordionSummary className={classes.accordioncolor}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Direct On-Line Feeder (DOL)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <DOLOutput />
                    </AccordionDetails>
                </Accordion>

                <Accordion >
                    <AccordionSummary className={classes.accordioncolor}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Reversing Direct On-Line Feeder (RDOL)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RDOLOutput />
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary className={classes.accordioncolor}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Star Delta Feeder (SD)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StarDeltaOutput />
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
} 