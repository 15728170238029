export const motorStartingTimeFactor = 80 / 100;
export const lraforContactorSelectionfactor = 1.2;
export const scpdSafetyFactor = 5 / 100;
// new  changes
export const newScpdSafetyFactor = 2 / 100;

export const scpdOlTrippingZoneTimeLowerBandFactor = 20 / 100;
export const scpdOlTrippingZoneTimeUpperBandFactor = 20 / 100;
export const scZoneLRALowerBandFactor = 20 / 100;
export const scZoneLRAUpperBandFactor = 20 / 100;
export const scLowerBandFactor = 10 / 100;
export const letThroughOfContactorSafetyFactor = 3;
export const olrSafetyFactor = 5 / 100;
// new changes
export const olrSafetyFactorNew = 2 / 100;

export const olrTrippingZoneTimeLowerBandFactor = 20 / 100;
export const olrTrippingZoneTimeUpperBandFactor = 20 / 100;
export const letThroughOfContactorFactor = 1000;
export const letThroughOfContactorThermalRatingFactor = 10;
/*****Star Delta Calculation Factors *****/
export const SDFlaphasefactor = 0.58;
export const SDLraDolfactor = 1.2;
export const SDLraSdfactor = 3;
export const SDstarcurrentfactor = 3;
export const lraOLselectionfactor = 3;
export const SCPDSDfactor = 5 / 100;
export const scSDZoneLRALowerBandFactor = 20 / 100;
export const scSDZoneLRAUpperBandFactor = 20 / 100;
export const scSDZoneLowerBandLRAFactor = 10 / 100;
export const letThroughOfSDContactorThermalRatingFactor = 10;
export const letThroughOfSDContactorFactor = 1000;
export const letThroughOfSDContactorSafetyFactor = 3;
export const SDContactorLRAFactor = 10 / 100;

export const SDContactorStarLRAFactor = 3 / 100;
// new changes
export const newSDContactorStarLRAFactor = 0 / 100; // for Type1

export const SDolrSafetyFactor = 5 / 100;
//new changes
export const newSDolrSafetyFactor = 2 / 100;

export const SDolrTrippingZoneTimeLowerBandFactor = 20 / 100;
export const SDolrTrippingZoneTimeUpperBandFactor = 20 / 100;
// export const LRAContactorToleranceFac = 10 / 100;
// new changes
export const newLRAContactorToleranceFacType1 = 0 / 100;
export const newLRAContactorToleranceFacType2 = 3 / 100;

/***** End of Star Delta Calculation Factors   *****/

export const C10 = "Class 10";
export const C20 = "Class 20";
export const C30 = "Class 30";
export const C40 = "Class 40";
export const displayYes = "Yes";
export const displayNo = "No";
export const displayCorrect = "Correct";
export const displayIncorrect = "Incorrect";
